<template>
<div>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <h2 class="modal-title gold"><font-awesome-icon icon="cogs" /> Configuration</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h3>General Settings</h3>
                <download-state class="mb-3" />
                <br>
                <upload-state />
                <br>
                <button class="btn btn-danger mb-3" @click="$store.commit('reset')">RESET</button>
                <br>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="true" v-model="$store.state.firstTime">
                    <label class="form-check-label">
                        Welcome Page
                    </label>
                </div>
                <h3 class="mt-4">Simulator Settings</h3>
                <div class="form-check" >
                    <input class="form-check-input" type="checkbox" :value="true" v-model="$store.state.simulator.enabled">
                    <label class="form-check-label">
                        Enable
                    </label>
                </div>
                <p>
                    Inputs configured on multiview page.
                </p>
            </div>
            <div class="col">
                <div class="clearfix">
                    <h3 class="float-left">SermonAudio Broadcasters</h3>
                    <button class="btn btn-success float-right" @click="$store.commit('sermonaudio/newBroadcaster')"><font-awesome-icon icon="plus" /> New</button>
                </div>
                <div v-for="bcId in Object.keys($store.state.sermonaudio.broadcasters)" :key="bcId">
                    <div class="form-group">
                        <label>Broadcaster ID</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="$store.state.sermonaudio.broadcasters[bcId].name">
                            <div class="input-group-append">
                                <button class="btn btn-outline-danger" type="button" @click="$store.commit('sermonaudio/rmBroadcaster',bcId)">Remove</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>API Key for {{ $store.state.sermonaudio.broadcasters[bcId].name }}</label>
                        <input type="password" class="form-control" v-model="$store.state.sermonaudio.broadcasters[bcId].apiKey">
                    </div>
                </div>
            </div>
            <div class="col">
                <h3>OBS Settings</h3>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="true" v-model="$store.state.obs.config.enabled">
                    <label class="form-check-label">
                        Enable
                    </label>
                </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="true" v-model="$store.state.obs.config.autoconnect">
                        <label class="form-check-label">
                            Auto-Connect
                        </label>
                    </div>
                <div class="form-group">
                    <label>Hostname</label>
                    <input type="text" class="form-control" v-model="$store.state.obs.config.hostname">
                </div>
                <div class="form-group">
                    <label>Port</label>
                    <input type="number" class="form-control" v-model="$store.state.obs.config.port">
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" class="form-control" v-model="$store.state.obs.config.password">
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <h3 class="float-left">ATEM Settings</h3>
                    <button class="btn btn-success float-right" @click="$store.commit('atem/new')" id="newAtemButton"><font-awesome-icon icon="plus" /> New</button>
                </div>
                <div v-for="(atemConfig,i) in $store.state.atem.switcherConfigs" :key="i">
                    <div class="form-group switcher-label" :class="{'border-top':i>0}">
                        <label>Name {{i}}</label>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="atemConfig.name">
                            <div class="input-group-append">
                                <button class="btn btn-outline-danger" type="button" @click="$store.commit('atem/remove',i)">Remove</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="true" v-model="atemConfig.enabled">
                        <label class="form-check-label">
                            {{atemConfig.name}} Enabled
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="true" v-model="atemConfig.autoconnect">
                        <label class="form-check-label">
                            {{atemConfig.name}} Auto-Connect
                        </label>
                    </div>
                    <div class="form-group">
                        <label>IP for {{atemConfig.name}}</label>
                        <input type="text" class="form-control" v-model="atemConfig.ip">
                    </div>
                    <div class="form-group">
                        <label>Port for {{atemConfig.name}}</label>
                        <input type="text" class="form-control" v-model="atemConfig.port">
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="true" v-model="atemConfig.secure">
                        <label class="form-check-label">TLS for {{atemConfig.name}}</label>
                    </div>
                    <div class="form-group">
                        <label>M/E 1 Inputs for {{atemConfig.name}}</label>
                        <draggable class="list-group" :move="(evt) => moveInputList(i,0,evt)" :list="$store.state.atem.switcherConfigs[i].me[0].inputs"
                            v-if="$store.state.atem.switcherConfigs[i].me[0].inputs.length > 0">
                            <div class="list-group-item flex" v-for="(input, mei) in $store.state.atem.switcherConfigs[i].me[0].inputs" :key="mei">
                                <span>{{ $store.getters['atem/inputName'](i,input) }}</span>
                                <button class="btn btn-sm btn-danger" @click="removeInputFromSwitcher(i,0,mei)">Remove</button>
                            </div>
                        </draggable>
                        <div v-else>
                            No Inputs Selected
                        </div>
                        <dropdown-button direction="up" class="dropdown-button mt-4" :elements="getInputList(i)" @selected="(element) => addInputToSwitcher(i,0,element)">
                            Add Input
                            <template v-slot:empty>Please connect to switcher first</template>
                        </dropdown-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.container-fluid {
    color: white;
}
.input-table {
    border: black solid 2px;
    margin-bottom: 10px;
    display: block;
}

.input-table td {
    border: black solid 2px;
    padding: 5px;
}

.dropdown-button {
    clear: both;
}

.list-group {
    color: black;
}

.flex {
    display: flex;
    justify-content: space-between;
}
</style>

<script>

import DropdownButton from '../components/basics/DropdownButton';
import Draggable from 'vuedraggable';
import DownloadState from '../components/basics/DownloadState';
import UploadState from '../components/basics/UploadState';

export default {
    components: {
        DropdownButton,
        Draggable,
        DownloadState,
        UploadState,
    },
    data() {
        return {
            switcher: "",
        };
    },
    methods: {
        getInputList(switcher) {
            const ret = {};
            if (!('inputNames' in this.$store.state.atem.switcherConfigs[switcher])) return {};
            for (const [key, input] of Object.entries(this.$store.state.atem.switcherConfigs[switcher].inputNames)){
                if ('longName' in input) ret[key] = input.longName;
                else if ('shortName' in input) ret[key] = input.shortName;
            }
            return ret;
        },
        addInputToSwitcher(switcher, me, input) {
            this.$store.commit("atem/addSelectedInput", {
                switcher,
                me,
                input,
            });
        },
        removeInputFromSwitcher(switcher, me, index){
            this.$store.commit("atem/removeSelectedInput", {
                switcher,
                me,
                index,
            });
        },
        moveInputList(switcher, me, evt){
            console.log('Moved', evt);
            this.$store.commit("atem/moveSelectedInput", {
                switcher,
                me,
                oldIndex: evt.draggedContext.index,
                index: evt.draggedContext.futureIndex,
            });
        },
    },
};
</script>
