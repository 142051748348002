<template>
<div>
    <div class="container mt-5 py-2">
        <h1 class="purple">Help Pages</h1>
        <nav class="nav">
            <router-link class="nav-link" :class="{active:$route.path == '/help/controls'}" :to="{path:'/help/controls'}">Controls</router-link>
            <router-link class="nav-link" :class="{active:$route.path == '/help/simulator'}" :to="{path:'/help/simulator'}">Simulator</router-link>
            <router-link class="nav-link" :class="{active:$route.path == '/help/relays'}" :to="{path:'/help/relays'}">Relays</router-link>
        </nav>
        <hr />
        <router-view></router-view>
    </div>
</div>
</template>

<style scoped>
.container {
    background-color: honeydew;
    border-radius: 10px;
}
</style>

<script>

export default {
    mounted() {
        console.log(this.$route);
    },
};
</script>
