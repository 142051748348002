<template>
    <real-button
        @click="toggleRecording"
        :state="state"
        :class="classes"
        :disabled="!$store.state.obs.connected"
        type="tally"
        @reghotkey="registerHotkey"
    >
        Rec
    </real-button>
</template>

<script>
const statusDef = [
    // LSB First; bit 1: OK, bit 2: started
    "stopping", // 00
    "stopped",  // 01
    "starting", // 10
    "started",  // 11
];

import RealButton from '@/components/basics/RealButton';

export default {
    name: 'ObsRecordButton',

    // Grid-Item Specific
    niceName: "OBS Record Button",
    description: "Record Button for OBS",
    options: {},
    width: 1,
    height: 1,
    hotkey(hotkeyData){
        this.$store.commit("obs/TOGGLE_RECORDING");
    },

    // Vue
    components: {
        RealButton,
    },
    computed: {
        classes(){
            const blink = ((this.$store.state.obs.recording == 0) || (this.$store.state.obs.recording == 2));
            return {
                blink, // (0,2)
            };
        },
        state(){
            const recStatus = this.$store.state.obs.recording;
            if (recStatus == 1) return "off";
            if (recStatus == 0 || recStatus == 3) return "live";
            if (recStatus == 2) return "preview";
            return "off";
        },
    },
    methods: {
        toggleRecording(){
            this.$store.commit("obs/TOGGLE_RECORDING");
        },
        registerHotkey(){
            this.$store.commit('completeKeyRegistration', {
                name: "OBS Record",
                componentName: this.$options.name,
                function: this.$options.hotkey,
            });
        },
    },
};
</script>
