<template>
    <div class="photo-viewer" :style="{'border-color': borderColor}">
        <img :src="src">
        <div v-if="$slots.default" class="overlay-title px-2 py-1 mx-auto"><slot></slot></div>
    </div>
</template>

<style lang="scss" scoped>
.photo-viewer {
    position: relative;
    display: inline-block;
    border: solid 2px;
    width: 25vw;
    height: 25vh;
    margin: 0px;
    padding: 0px;
    background-color: black;
}

.photo-viewer img {
    height: 100%;
    width: 100%;
    position:absolute;
    left:0;
}

.photo-viewer .overlay-title {
    line-height: normal;
    position: absolute;
    display: flex;
    bottom: 5px;
    border-radius: 3px;
    background: #333333;
    border: white solid 2px;
    max-width: 80%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: white;
}
</style>

<script>
export default {
    props: {
        "src": String,
        "live": Boolean,
        "preview": Boolean,
    },
    computed: {
        borderColor(){
            if (this.live) return "red";
            if (this.preview) return "#00CC00";
            return "white";
        },
    },
};
</script>
