import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.vue';
import store from './store/';
import router from './router';
import './registerServiceWorker';
import VueTour from 'vue-tour';

// Tour
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

// Masks
import VueMask from 'v-mask';
Vue.use(VueMask, {
    placeholders: {
        'F': /[0-2]/,
    },
});

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUserSecret,
    faCogs,
    faCog,
    faKeyboard,
    faGamepad,
    faQuestion,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
    faGitlab,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add([faUserSecret, faCogs, faCog, faKeyboard, faGamepad, faQuestion, faGitlab, faPlus]);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Normal initialization
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    if (to.name == 'welcome' && !store.state.firstTime){
        next({name: "controls"});
    } else {
        next();
    }
});

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');
