<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <router-link v-if="$store.state.firstTime" :to="{name:'welcome'}" class="navbar-brand">Streaming Dashboard</router-link>
        <router-link v-else :to="{name:'controls'}" class="navbar-brand">Streaming Dashboard</router-link>
        <button class="navbar-toggler" type="button" @click="opened = !opened">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" :class="{show:opened}" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto" @click="opened=false">
                <li class="nav-item" id="controlHeader">
                    <router-link class="nav-link" :to="{name:'controls'}" :class="{active: $route.name == 'controls'}"><font-awesome-icon class="steelblue" icon="gamepad" /> Controls</router-link>
                </li>
                <li class="nav-item ml-2" id="configHeader">
                    <router-link class="nav-link" :to="{name:'config'}" :class="{active: $route.name == 'config'}"><font-awesome-icon class="gold" icon="cog" /> Configuration</router-link>
                </li>
                <li class="nav-item ml-2" id="helpHeader">
                    <router-link :to="{path:'/help'}" class="nav-link"  :class="{active: $route.path.startsWith('/help') }">
                        <font-awesome-icon class="purple" icon="question" /> Help
                    </router-link>
                </li>
                <li class="nav-item ml-2">
                    <a href="https://gitlab.com/churchtechhelp/streaming-dashboard/web" class="nav-link" target="_BLANK">
                        <font-awesome-icon :icon="['fab', 'gitlab']" class="orange-red" /> GitLab
                    </a>
                </li>
            </ul>
            <ul v-if="$route.name == 'controls'" class="navbar-nav satuses">
                <li class="nav-item" v-if="$store.state.obs.config.enabled">
                    <a class="btn btn-success" v-if="this.$store.state.obs.connected" @click="$store.commit('obs/DISCONNECT')">Disconnect OBS</a>
                    <a class="btn btn-warning" v-else-if="this.$store.state.obs.connecting" @click="$store.commit('obs/DISCONNECT')">Disconnect OBS</a>
                    <a class="btn btn-danger" v-else @click="$store.commit('obs/CONNECT')">Connect OBS</a>
                </li>
                <li class="nav-item ml-2" v-for="(atemConfig,i) in $store.state.atem.switcherConfigs" :key="i">
                    <a class="btn btn-success" v-if="$store.state.atem.switcherConfigs[i].enabled && $store.state.atem.switcherConnections[i].connected" @click="$store.commit('atem/DISCONNECT', {switcher:i})">Disconnect ATEM {{ i }}</a>
                    <a class="btn btn-warning" v-else-if="$store.state.atem.switcherConfigs[i].enabled && $store.state.atem.switcherConnections[i].open" @click="$store.commit('atem/DISCONNECT', {switcher:i})">Disconnect ATEM {{ i }}</a>
                    <a class="btn btn-danger" v-else-if="$store.state.atem.switcherConfigs[i].enabled" @click="$store.commit('atem/CONNECT', {switcher:i})">Connect ATEM {{ i }}</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<style lang="scss" scoped>
.navbar {
    margin-bottom: 40px;
    padding-top: 0;
    padding-bottom: 0;
}
.navbar-nav {
    white-space: nowrap;
    height: 100%;
}
.nav-item {
    height: 100%;
}
.nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
</style>

<script>
export default {
    data() {
        return {
            opened: false,
        };
    },
};
</script>
