<template>
    <tee-slider
        @change="updateTransPos"
        @mousedown="stopAutoTrans"
        @mouseup="mousedown = false"
        @mouseleave="mousedown = false"
        :position="position"
        :max="10000"
        :disabled="!this.$store.state.atem.switcherConnections[this.switcher].connected"
    />
</template>

<script>
import TeeSlider from '@/components/basics/TeeSlider';

export default {
    name: "AtemTeeSlider",

    // Grid Item Specific
    niceName: "ATEM Tee Slider",
    description: "T-Slider to manually control a transition",
    width: 2,
    height: 3,
    options: {
        switcher: {
            name: "Switcher",
            default: 0,
            type: "select",
            options() {
                return this.$store.getters['atem/switchers'];
            },
        },
        me: {
            name: "ME",
            default: 0,
            type: "toggle",
            options(){
                if (this.selectedComponentOptions["switcher"] === "") return "Please select a switcher";
                if (!this.$store.getters['atem/connected'](this.selectedComponentOptions["switcher"])) return "Needs to be connected";
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me < 2) return { 0: "NO M/E" };
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me == 2) return { 0: "M/E 1", 1: "M/E 2" };
            },
        },
    },

    // Vue
    components: {
        TeeSlider,
    },
    data() {
        return {
            mousedown: false,
        };
    },
    props: {
        switcher: {
            type: Number,
            required: true,
        },
        me: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        position(){
            if (!this.$store.state.atem.switcherConnections[this.switcher].connected) return 0;
            return this.$store.state.atem.switcherStates[this.switcher].me[this.me].transitionPosition;
        },
    },
    methods: {
        updateTransPos(val){ // Callback from slider
            if (!this.$store.state.atem.switcherConnections[this.switcher].connected) return;
            if (this.$store.state.atem.switcherStates[this.switcher].me[this.me].transitionPosition != val) {
                this.$store.commit('atem/SEND_COMMAND', {
                    switcher: this.switcher,
                    command: 'SetTransitionPosition',
                    data: {
                        me: this.me,
                        position: val,
                    },
                });
            }
        },
        stopAutoTrans(){
            this.mousedown = true;
            if (!this.$store.state.atem.switcherConnections[this.switcher].connected) return;
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: 'SetTransitionPosition',
                data: {
                    me: this.me,
                    position: this.$store.state.atem.switcherStates[this.switcher].me[this.me].transitionPosition,
                },
            });
        },
    },
};
</script>
