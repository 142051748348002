<template>
    <div>
        <h1>Controls Page</h1>
        <p>
            The controls page is where you control all your devices, of course.
        </p>
        <h2>Connections</h2>
        <p>
            While in the control panel, you will be able to see the status of the various connections in the header on the left.
            <br>
            A <span class="badge badge-success">Green</span> status means you are connected.
            <br>
            A <span class="badge badge-warning">Yellow</span> status means a connection is trying to be established.
            <br>
            A <span class="badge badge-danger">Red</span> status means there is no connection.
        </p>
        <h2>The Grid</h2>
        <p>
        The controls page is a grid of control elements.
        Controls can be placed anywhere on the screen.
        </p>
        <p>
        To begin editing the grid, press the <plus-icon /> icon in the bottom left corner.
        When done, either close the <i>Edit Layout</i> window or press the <check-icon /> icon in the bottom left corner.
        </p>
        <p>
            To add a component, do the following:
            <ol>
                <li>Select the control's category</li>
                <li>Select the control you want</li>
                <li>Select or enter any information needed</li>
                <li>Press the <span class="badge badge-success">Add</span> button</li>
            </ol>
        </p>
        <p>
            To remove a control, you can click on the X on the corner of the control you want to remove while in edit mode.<br>
            You can also reconfigure a control while in edit mode by clicking on the gear icon.
            The selected control will be highlighted while its settings are open.
        </p>
        <h2>Hotkeys</h2>
        <p>
            Any button can be controlled by a keyboard button.
            To open the hotkey window, click the <font-awesome-icon :icon="['fas','keyboard']" /> hotkey button in the bottom left corner.
            The hotkey setup process is a three step process.
            <ol>
                <li>Press the <span class="badge badge-primary">Add Hotkey</span> button</li>
                <li>Press the key on the keyboard</li>
                <li>Click on the button you want to assign to that key</li>
            </ol>
            You can remove a hotkey by clicking on the <trash-icon /> trash icon.
        </p>
        <h2>Offline</h2>
        <p>
            If you would like to make the dashboard available even if there is no internet,
            you can tell the browser to treat the dashboard origin as secure.<br>
            In the header, enter `chrome://flags/#unsafely-treat-insecure-origin-as-secure`<br>
            Enter `http://streamer.churchtech.help` and restart the browser.<br>
            This will allow what is known as a service worker to cache the site.
            Normally, insecure domains are not allowed to have service workers.
            However, currently the dashboard cannot run on a secure domain, as it requires
            all the websockets that we use to connect to devices to also be secure.
            Once a way to _easily_ make devices secure is figured out, this potentially unsafe config will no longer be needed.
        </p>
    </div>
</template>

<script>
import {
    PlusIcon,
    CheckIcon,
    TrashIcon,
} from 'vue-feather-icons';

export default {
    components: {
        PlusIcon,
        CheckIcon,
        TrashIcon,
    },
};
</script>
