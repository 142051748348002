export function fromKeycode(key){
    const keyboardMap = new Array(255);
    keyboardMap[3] = "CANCEL";
    keyboardMap[6] = "HELP";
    keyboardMap[8] = "BACK_SPACE";
    keyboardMap[9] = "TAB";
    keyboardMap[12] = "CLEAR";
    keyboardMap[13] = "ENTER";
    keyboardMap[14] = "ENTER_SPECIAL";
    keyboardMap[16] = "SHIFT";
    keyboardMap[17] = "CONTROL";
    keyboardMap[18] = "ALT";
    keyboardMap[19] = "PAUSE";
    keyboardMap[20] = "CAPS_LOCK";
    keyboardMap[21] = "KANA";
    keyboardMap[22] = "EISU";
    keyboardMap[23] = "JUNJA";
    keyboardMap[24] = "FINAL";
    keyboardMap[25] = "HANJA";
    keyboardMap[27] = "ESCAPE";
    keyboardMap[28] = "CONVERT";
    keyboardMap[29] = "NONCONVERT";
    keyboardMap[30] = "ACCEPT";
    keyboardMap[31] = "MODECHANGE";
    keyboardMap[32] = "SPACE";
    keyboardMap[33] = "PAGE_UP";
    keyboardMap[34] = "PAGE_DOWN";
    keyboardMap[35] = "END";
    keyboardMap[36] = "HOME";
    keyboardMap[37] = "LEFT";
    keyboardMap[38] = "UP";
    keyboardMap[39] = "RIGHT";
    keyboardMap[40] = "DOWN";
    keyboardMap[41] = "SELECT";
    keyboardMap[42] = "PRINT";
    keyboardMap[43] = "EXECUTE";
    keyboardMap[44] = "PRINTSCREEN";
    keyboardMap[45] = "INSERT";
    keyboardMap[46] = "DELETE";
    keyboardMap[48] = "0";
    keyboardMap[49] = "1";
    keyboardMap[50] = "2";
    keyboardMap[51] = "3";
    keyboardMap[52] = "4";
    keyboardMap[53] = "5";
    keyboardMap[54] = "6";
    keyboardMap[55] = "7";
    keyboardMap[56] = "8";
    keyboardMap[57] = "9";
    keyboardMap[58] = "COLON";
    keyboardMap[59] = "SEMICOLON";
    keyboardMap[60] = "LESS_THAN";
    keyboardMap[61] = "EQUALS";
    keyboardMap[62] = "GREATER_THAN";
    keyboardMap[63] = "?";
    keyboardMap[64] = "AT";
    keyboardMap[65] = "A";
    keyboardMap[66] = "B";
    keyboardMap[67] = "C";
    keyboardMap[68] = "D";
    keyboardMap[69] = "E";
    keyboardMap[70] = "F";
    keyboardMap[71] = "G";
    keyboardMap[72] = "H";
    keyboardMap[73] = "I";
    keyboardMap[74] = "J";
    keyboardMap[75] = "K";
    keyboardMap[76] = "L";
    keyboardMap[77] = "M";
    keyboardMap[78] = "N";
    keyboardMap[79] = "O";
    keyboardMap[80] = "P";
    keyboardMap[81] = "Q";
    keyboardMap[82] = "R";
    keyboardMap[83] = "S";
    keyboardMap[84] = "T";
    keyboardMap[85] = "U";
    keyboardMap[86] = "V";
    keyboardMap[87] = "W";
    keyboardMap[88] = "X";
    keyboardMap[89] = "Y";
    keyboardMap[90] = "Z";
    keyboardMap[91] = "Windows/Left ⌘";
    keyboardMap[93] = "Menu/Right ⌘";
    keyboardMap[95] = "SLEEP";
    keyboardMap[96] = "NUMPAD0";
    keyboardMap[97] = "NUMPAD1";
    keyboardMap[98] = "NUMPAD2";
    keyboardMap[99] = "NUMPAD3";
    keyboardMap[100] = "NUMPAD4";
    keyboardMap[101] = "NUMPAD5";
    keyboardMap[102] = "NUMPAD6";
    keyboardMap[103] = "NUMPAD7";
    keyboardMap[104] = "NUMPAD8";
    keyboardMap[105] = "NUMPAD9";
    keyboardMap[106] = "NUMPAD*";
    keyboardMap[107] = "NUMPAD+";
    keyboardMap[108] = "SEPARATOR";
    keyboardMap[109] = "NUMPAD-";
    keyboardMap[110] = "NUMPAD.";
    keyboardMap[111] = "NUMPAD/";
    keyboardMap[112] = "F1";
    keyboardMap[113] = "F2";
    keyboardMap[114] = "F3";
    keyboardMap[115] = "F4";
    keyboardMap[116] = "F5";
    keyboardMap[117] = "F6";
    keyboardMap[118] = "F7";
    keyboardMap[119] = "F8";
    keyboardMap[120] = "F9";
    keyboardMap[121] = "F10";
    keyboardMap[122] = "F11";
    keyboardMap[123] = "F12";
    keyboardMap[124] = "F13";
    keyboardMap[125] = "F14";
    keyboardMap[126] = "F15";
    keyboardMap[127] = "F16";
    keyboardMap[128] = "F17";
    keyboardMap[129] = "F18";
    keyboardMap[130] = "F19";
    keyboardMap[131] = "F20";
    keyboardMap[132] = "F21";
    keyboardMap[133] = "F22";
    keyboardMap[134] = "F23";
    keyboardMap[135] = "F24";
    keyboardMap[144] = "NumLock";
    keyboardMap[145] = "ScrollLock";
    keyboardMap[146] = "WIN_OEM_FJ_JISHO";
    keyboardMap[147] = "WIN_OEM_FJ_MASSHOU";
    keyboardMap[148] = "WIN_OEM_FJ_TOUROKU";
    keyboardMap[149] = "WIN_OEM_FJ_LOYA";
    keyboardMap[150] = "WIN_OEM_FJ_ROYA";
    keyboardMap[160] = "^";
    keyboardMap[161] = "!";
    keyboardMap[162] = '"';
    keyboardMap[163] = "#";
    keyboardMap[164] = "$";
    keyboardMap[165] = "%";
    keyboardMap[166] = "&";
    keyboardMap[167] = "_";
    keyboardMap[168] = "(";
    keyboardMap[169] = ")";
    keyboardMap[170] = "*";
    keyboardMap[171] = "+";
    keyboardMap[172] = "|";
    keyboardMap[173] = "HYPHEN_MINUS";
    keyboardMap[174] = "{";
    keyboardMap[175] = "}";
    keyboardMap[181] = "Mute";
    keyboardMap[182] = "Volume Down";
    keyboardMap[183] = "Volume Up";
    keyboardMap[186] = ";";
    keyboardMap[187] = "=";
    keyboardMap[188] = ",";
    keyboardMap[189] = "-";
    keyboardMap[190] = ".";
    keyboardMap[191] = "/";
    keyboardMap[192] = "`";
    keyboardMap[219] = "[";
    keyboardMap[220] = "\\";
    keyboardMap[221] = "]";
    keyboardMap[222] = "'";
    keyboardMap[224] = "META";
    keyboardMap[225] = "ALTGR";
    keyboardMap[227] = "WIN_ICO_HELP";
    keyboardMap[228] = "WIN_ICO_00";
    keyboardMap[230] = "WIN_ICO_CLEAR";
    keyboardMap[233] = "WIN_OEM_RESET";
    keyboardMap[234] = "WIN_OEM_JUMP";
    keyboardMap[235] = "WIN_OEM_PA1";
    keyboardMap[236] = "WIN_OEM_PA2";
    keyboardMap[237] = "WIN_OEM_PA3";
    keyboardMap[238] = "WIN_OEM_WSCTRL";
    keyboardMap[239] = "WIN_OEM_CUSEL";
    keyboardMap[240] = "WIN_OEM_ATTN";
    keyboardMap[241] = "WIN_OEM_FINISH";
    keyboardMap[242] = "WIN_OEM_COPY";
    keyboardMap[243] = "WIN_OEM_AUTO";
    keyboardMap[244] = "WIN_OEM_ENLW";
    keyboardMap[245] = "WIN_OEM_BACKTAB";
    keyboardMap[246] = "ATTN";
    keyboardMap[247] = "CRSEL";
    keyboardMap[248] = "EXSEL";
    keyboardMap[249] = "EREOF";
    keyboardMap[250] = "PLAY";
    keyboardMap[251] = "ZOOM";
    keyboardMap[253] = "PA1";
    keyboardMap[254] = "WIN_OEM_CLEAR";

    const keyLocations = {
        0: 'General',
        1: 'Left',
        2: 'Right',
        3: 'Numpad',
    };

    let value = keyboardMap[key & 0xFF];
    if ((key & 0x100) === 0x100){
        value = 'ALT + ' + value;
    }
    if ((key & 0x200) === 0x200){
        value = 'CTRL + ' + value;
    }
    if ((key & 0x400) === 0x400){
        value = 'SHIFT + ' + value;
    }
    return value;
}
