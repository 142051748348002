<template>
    <div class="status-button" :class="color">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
$color-list: (
    "green"  #00CC00 #00DD00,
    "yellow" rgb(161, 161, 0) rgb(196, 196, 30),
    "red"    #FF3333 #FF5555,
    "grey"     #999999 #888888,
);

@each $value in $color-list {
    .status-button.is-#{nth($value,1)} {
        background-color: #{nth($value,2)};
    }
}

.status-button {
    display: inline-block;
    padding: 2px 5px;
    color: white;
    border-radius: 5px;
}

</style>

<script>
export default {
    name: 'StatusButton',
    props: {
        status: Boolean,
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentColor: this.calcColor(this.status),
        };
    },
    computed: {
        color(){
            return "is-" + this.currentColor;
        },
    },
    methods: {
        calcColor(newState){
            if (newState === null || this.error === true) return 'grey';
            if (newState === true) return 'green';
            return 'red';
        },
    },
};
</script>
