import { render, staticRenderFns } from "./ConfigPage.vue?vue&type=template&id=58c0e347&scoped=true&"
import script from "./ConfigPage.vue?vue&type=script&lang=js&"
export * from "./ConfigPage.vue?vue&type=script&lang=js&"
import style0 from "./ConfigPage.vue?vue&type=style&index=0&id=58c0e347&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c0e347",
  null
  
)

export default component.exports