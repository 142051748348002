<template>
    <button-group label="Simulator Transitions" :columns="3" :rows="2">
        <real-button type="select" state="0" @click="cut" @reghotkey="registerHotkey('cut')">Cut</real-button>
        <real-button
            type="live"
            :state="$store.state.simulator.autoRunning"
            @click="autoTrans"
            :hotkey="autoTrans"
            name="Simulator Auto"
            @reghotkey="registerHotkey('auto')"
        >
            Auto
        </real-button>
        <div class="auto-speed-div">
            <input
                v-mask="'#:F#'"
                type="text"
                class="form-inline form-control auto-speed"
                size="4"
                maxlength="4"
                v-model="autoSpeed"
                @keyup.enter.stop="setAutoSpeed"
                @focus="$store.commit('ignoreKeys')"
                @blur="blurAutoSpeed"
            />
        </div>
        <br>
        <real-button
            type="select"
            :state="$store.state.simulator.transitionType == 'mix'"
            @click="$store.commit('simulator/setTransitionType','mix')"
            @reghotkey="registerHotkey('mix')"
        >
            Mix
        </real-button>
        <real-button
            type="select"
            :state="$store.state.simulator.transitionType == 'wipe'"
            @click="$store.commit('simulator/setTransitionType','wipe')"
            @reghotkey="registerHotkey('wipe')"
        >
            Wipe
        </real-button>
    </button-group>
</template>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';

export default {
    name: "SimulatorTransitionPanel",

    // Grid-Item Specific
    niceName: "Transition Panel",
    description: "Simulation transition control",
    options: {},
    width: 2,
    height: 2,
    hotkey(hotkeyData) {
        if (hotkeyData.action == 'cut') {
            this.$store.commit('simulator/cutLive');
        } else if (hotkeyData.action == 'auto') {
            if (this.$store.state.simulator.autoRunning) {
                this.$store.commit("simulator/stopAuto");
            } else {
                this.$store.dispatch("simulator/autoTransition");
            }
        } else {
            this.$store.commit('simulator/setTransitionType', hotkeyData.action);
        }
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    data() {
        return {
            autoSpeed: this.$store.state.simulator.autoSpeed,
        };
    },
    computed: {
        autoSpeedComp(){
            return this.$store.state.simulator.autoSpeed;
        },
    },
    
    watch: {
        autoSpeedComp(){
            let s = this.$store.state.simulator.autoSpeed.toString();
            while (s.length < 3) s = "0" + s;
            this.autoSpeed = s.replace(/(\d)(\d\d)/, '$1:$2');
        },
    },
    methods: {
        cycleTransType(){
            const currentType = this.$store.state.simulator.transitionType;
            const types = ["mix", "wipe"];
            const nextIndex = (types.indexOf(currentType)+1)%(types.length);
            this.$store.commit('simulator/setTransitionType', types[nextIndex]);
        },
        startTrans(){ // Called by anything transitioning
            this.transitioning = true;
            if (this.transitionTimer) clearTimeout(this.transitionTimer);
        },
        cut(){
            this.$store.commit('simulator/cutLive');
        },
        autoTrans(){
            if (this.$store.state.simulator.autoRunning) {
                this.$store.commit("simulator/stopAuto");
            } else {
                this.$store.dispatch("simulator/autoTransition");
            }
        },
        setAutoSpeed(event){
            if (/^\d:$/.test(this.autoSpeed)) {
                this.autoSpeed += "00";
            }
            if (/^\d:[0-2]\d$/.test(this.autoSpeed)) {
                this.$store.commit('simulator/setAutoSpeed', Number(this.autoSpeed.replace(':', '')));
                event.target.blur();
            }
        },
        blurAutoSpeed(){
            let s = this.$store.state.simulator.autoSpeed.toString();
            while (s.length < 3) s = "0" + s;
            this.autoSpeed = s.replace(/(\d)(\d\d)/, '$1:$2');
            this.$store.commit("listenKeys");
        },
        registerHotkey(action){
            this.$store.commit('completeKeyRegistration', {
                name: 'Simulator ' + action.charAt(0).toUpperCase() + action.slice(1) + ' Transition',
                componentName: this.$options.name,
                function: this.$options.hotkey,
                action,
            });
        },
    },
};
</script>

<style scoped>

.auto-speed-div {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
}

.auto-speed {
  width: 60px;
  display: inline-block;
  color: yellow;
  background-color: black;
  border: none;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 0px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.auto-speed:focus {
  color: red;
  background-color: black;
  border: none;
}
</style>
