<template>
    <real-button
        type="program"
        :state="state"
        :class="classes"
        :disabled="!$store.getters['atem/connected'](switcher)"
        @click="runFade"
        @reghotkey="registerHotkey"
    >FTB</real-button>
</template>

<script>
import RealButton from '@/components/basics/RealButton';
export default {
    name: 'AtemFadeToBlack',

    // Grid-Item Specific
    niceName: "ATEM FTB Button",
    description: "ATEM Fade to Black Button",
    options: {
        switcher: {
            name: "Switcher",
            default: 0,
            type: "select",
            options() {
                return this.$store.getters['atem/switchers'];
            },
        },
        me: {
            name: "ME",
            default: 0,
            type: "toggle",
            options(){
                if (this.selectedComponentOptions["switcher"] === "") return "Please select a switcher";
                if (!this.$store.getters['atem/connected'](this.selectedComponentOptions["switcher"])) return "Needs to be connected";
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me < 2) return { 0: "NO M/E" };
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me == 2) return { 0: "M/E 1", 1: "M/E 2" };
            },
        },
    },
    width: 1,
    height: 1,
    hotkey(hotkeyData){
        this.$store.commit('atem/SEND_COMMAND', {
            switcher: hotkeyData.switcher,
            command: "RunFTB",
            data: {
                me: hotkeyData.me,
            },
        });
    },

    // Vue
    components: {
        RealButton,
    },
    props: {
        switcher: {
            type: Number,
            required: true,
        },
        me: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        classes(){
            if (!this.$store.getters['atem/connected'](this.switcher)) return {};
            const blink = this.$store.state.atem.switcherStates[this.switcher].me[this.me].ftb.transitioning;
            return {
                blink,
            };
        },
        state(){
            if (!this.$store.getters['atem/connected'](this.switcher)) return false;
            return this.$store.state.atem.switcherStates[this.switcher].me[this.me].ftb.black;
        },
    },
    methods: {
        runFade(){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: "RunFTB",
                data: {
                    me: this.me,
                },
            });
        },
        registerHotkey(){
            name += " DSK" + (keyer + 1);
            this.$store.commit('completeKeyRegistration', {
                name: "ATEM FTB " + (this.me+1),
                componentName: this.$options.name,
                function: this.$options.hotkey,
                me: this.me,
                switcher: this.switcher,
            });
        },
    },
};
</script>
