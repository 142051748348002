import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';
import simulator from './simulator';
import obs from './obs';
import obsPlugin from './obsPlugin';
import atem from './atem';
import atemPlugin from './atemPlugin';
import sermonaudio from './sermonaudio';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        simulator,
        obs,
        atem,
        sermonaudio,
    },
    state: {
        ignoreKeys: false,
        editing: false,
        newKeyListening: false,
        hotkeys: {},
        layout: [],
        firstTime: true,
        tutorialStarted: false,
    },
    mutations: {
        // Keys
        listenKeys(store){
            store.ignoreKeys = false;
        },
        ignoreKeys(store){
            store.ignoreKeys = true;
        },
        // Window edit mode
        setEdit(store, status){
            store.editing = status;
        },
        addGridComponent(store, data){
            store.layout.push(data);
        },
        updateLayoutProp(store, index, prop, value){
            Vue.set(store.layout[index].props, prop, value);
        },
        removeGridComponent(store, index){
            for (let i = 0; i < store.layout.length; i++){
                if (store.layout[i].i == index){
                    store.layout.splice(i, 1);
                }
            }
        },
        initKeyRegistration(store){
            if (store.newKeyListening !== false) return;
            store.newKeyListening = true;
        },
        startKeyRegistration(store, key){
            if (store.newKeyListening !== true) return;
            if (typeof key != 'number') {
                return;
            }
            store.newKeyListening = key;
        },
        completeKeyRegistration(store, component){
            if (typeof store.newKeyListening == 'boolean') return;
            Vue.set(store.hotkeys, store.newKeyListening, component);
            store.newKeyListening = false;
        },
        cancelKeyRegistration(store){
            store.newKeyListening = false;
        },
        removeHotkey(store, key){
            Vue.delete(store.hotkeys, key);
        },
        reregisterHotkey(store, [keycode, data]){
            Vue.set(store.hotkeys, keycode, data);
        },
        reset(store){
            if (confirm("Are you sure you want to reset the state?")){
                Object.assign(store, require('./default.json'));
            }
        },
        completeFirstTime(store){
            store.firstTime = false;
        },
        restartFirstTime(store){
            store.firstTime = true;
        },
        startTutorial(store){
            store.tutorialStarted = true;
        },
        completeTutorial(store){
            store.tutorialStarted = false;
        },
        replaceLayout(store, layout){
            store.layout = layout;
        },
        replaceHotkeys(store, hotkeys) {
            store.hotkeys = hotkeys;
            // should be done on config page, so it should get updated when we go to controls page (mounted)
        },
    },
    getters: {
        isFirstTime(state){
            return state.firstTime;
        },
    },
    plugins: [
    // Things we want stored in localstorage
        createMultiTabState({
            'statesPaths': [
                'switcher',
                'simulator.transitionRunning',
                'simulator.transitionState',
                'simulator.transitionType',
                'simulator.live',
                'simulator.preview',
                'simulator.autoSpeed',
                'simulator.inputs',
                'simulator.enabled',
                'obs.config',
                'atem.switcherConfigs',
                'layout',
                'hotkeys',
                'firstTime',
                'sermonaudio.broadcasters',
            ],
        }),
        obsPlugin(),
        atemPlugin(),
    ],
});
