import Vue from 'vue';
import Router  from 'vue-router';
import store from './store/index';

import ControlsPage from '@/pages/ControlsPage';
import MultiviewPage from '@/pages/MultiviewPage';
import ProgramPage from '@/pages/ProgramPage';
import ConfigPage from '@/pages/ConfigPage';
import WelcomePage from '@/pages/WelcomePage';

// Help Pages
import HelpPage from '@/pages/HelpPage';
import HelpNonePage from '@/pages/help/None';
import HelpRelaysPage from '@/pages/help/Relays';
import HelpControlsPage from '@/pages/help/Controls';
import HelpSimulatorPage from '@/pages/help/Simulator';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            name: 'welcome',
            component: WelcomePage,
        },
        {
            path: '/dashboard',
            name: "controls",
            component: ControlsPage,
        },
        {
            path: '/config',
            name: "config",
            component: ConfigPage,
        },
        {
            path: '/multiview',
            name: 'multiview',
            component: MultiviewPage,
        },
        {
            path: '/program',
            name: 'program',
            component: ProgramPage,
        },
        {
            path: '/help',
            component: HelpPage,
            children: [
                {
                    path: "",
                    name: 'help',
                    component: HelpNonePage,
                },
                {
                    path: 'relays',
                    name: 'help-relay',
                    component: HelpRelaysPage,
                },
                {
                    path: 'controls',
                    name: 'help-controls',
                    component: HelpControlsPage,
                },
                {
                    path: 'simulator',
                    name: 'help-simulator',
                    component: HelpSimulatorPage,
                },
            ],
        },
    ],
});
