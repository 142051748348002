<template>
    <div :class="classes">
        <button class="btn btn-outline-secondary dropdown-toggle" :class="{disabled: disabled, show: opened}" @click="open">
            <slot></slot>
        </button>
        <div class="dropdown-menu" v-if="elementsIsEmpty" :class="{show: opened}">
            <a class="dropdown-item">
                <slot name="empty">No Options Available</slot>
            </a>
        </div>
        <div class="dropdown-menu" v-else-if="Array.isArray(elements)" v-show="opened">
            <a v-for="(value, index) in elements" :key="index" class="dropdown-item" @click="select(index)">
                {{ Resolve(value) }}
            </a>
        </div>
        <div class="dropdown-menu" v-else-if="typeof elements == 'object'" :class="{show: opened}">
            <a v-for="[key, value] of Object.entries(elements)" :key="key" class="dropdown-item" @click="select(key)">
                {{ Resolve(value) }}
            </a>
        </div>
        <div class="dropdown-menu" v-else-if="typeof elements == 'string'" v-show="opened">
            <a v-for="(value, index) in elements.split(',')" :key="index" class="dropdown-item" @click="select(index)">
                {{ Resolve(value) }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            opened: false,
        };
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        elements: {
            type: [Array, Object, String],
            required: true,
        },
        direction: {
            type: String,
            default: "down",
        },
    },
    computed: {
        classes(){
            return {
                dropup: this.direction == "up",
                dropleft: this.direction == "left",
                dropright: this.direction == "right",
            };
        },
        elementsIsEmpty(){
            if (Array.isArray(this.elements)) {
                return this.elements.length < 1;
            } else if (typeof this.elements == 'object') {
                return Object.keys(this.elements).length < 1;
            } else if (typeof elements == 'string'){
                return this.elements == "";
            }
            return true;
        },
    },
    methods: {
        Resolve(options){
            if (typeof options == 'function') {
                return options.call(this);
            }
            return options;
        },
        open(){
            console.log("Clicked dropdown. Elements are empty =", this.elementsIsEmpty);
            if (this.disabled) return;
            this.opened = !this.opened;
        },
        select(value){
            this.$emit('selected', value);
            this.opened = false;
        },
    },
    watch: {
        disabled(val, old){
            if (val === true) this.opened = false;
        },
    },
};
</script>
