<template>
    <div class="moveable-modal" :style="modalStyle" v-show='visible'>
        <div class="modal-header" @mousedown.prevent="dragMouseDown" :style="{background: headerColor}">
            <span><slot name="header"></slot></span>
            <span class="close" @click="close"></span>
        </div>
        <div class="modal-body">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.moveable-modal {
    position: absolute;
    z-index: 20;
    border: 1px solid #d3d3d3d8;
    background: rgba(100, 100, 100, 0.8);
    color: white;
    width: 350px;
    min-height: 300px;
    padding: 0;
}

.modal-header {
    width: 100%;
    margin: 0;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 3px;
    padding-right: 5px;
    cursor: move;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.modal-body {
    margin: 10px;
}

.close {
    cursor: pointer;

    &:after, &:before {
        content:"";
        height:20px;
        width:20px;
        margin: 5px;
        border-top:1px solid red;
        position:absolute;
        top:7px;
        right:-8px;
        transform: rotate(-45deg);
    }
        
    &:before {
        right:6px;
        transform: rotate(45deg);
    }
    
    &:hover {
        opacity: 0.3;
    }
}
</style>

<script>
export default {
    data() {
        return {
            panelY: 50,
            panelX: 50,
            dragging: false,
            dragX: null,
            dragY: null,
        };
    },
    props: {
        visible: {
            type: Boolean,
            default: true,
        },
        headerColor: {
            type: String,
            default: 'green',
        },
    },
    computed: {
        modalStyle(){
            return {
                top: this.panelY + 'px',
                left: this.panelX + 'px',
            };
        },
    },
    methods: {
        dragMouseDown(e){
            this.dragging = true;
            this.dragX = e.clientX;
            this.dragY = e.clientY;
            document.onmouseup = this.dragMouseUp;
            document.onmousemove = this.dragMouseMove;
        },
        dragMouseMove(e){
            if (!this.dragging) return;
            e.preventDefault();
            const diffX = this.dragX - e.clientX;
            const diffY = this.dragY - e.clientY;
            this.dragX = e.clientX;
            this.dragY = e.clientY;
            this.panelX -= diffX;
            this.panelY -= diffY;
            if (this.panelX < 2) this.panelX = 2;
            if (this.panelY < 2) this.panelY = 2;
            if (this.panelX > (window.innerWidth - 302)) this.panelX = window.innerWidth - 302;
            if (this.panelY > (window.innerHeight - 302)) this.panelY = window.innerHeight - 302;
        },
        dragMouseUp(e){
            this.dragging = false;
            document.onmouseup = null;
            document.onmousemove = null;
        },
        close(){
            this.$emit('close');
        },
    },
};
</script>
