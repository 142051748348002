<template>
    <button-group label="OBS Scenes" :columns="$store.state.obs.scenes.length">
        <div v-if="$store.state.obs.connected">
        <real-button
            :key="i"
            v-for="i in $store.state.obs.scenes"
            type="tally"
            :state="getState(i)"
            :class="{blink: ($store.state.obs.transitioning === i && $store.state.obs.transitionName != 'Cut') }"
            @click="changeState(i)"
            @reghotkey="registerHotkey(i)"
        >{{ i }}</real-button>
        </div>
        <div v-else class="disconnected">
            Disconnected
        </div>
    </button-group>
</template>

<style lang="scss" scoped>
.disconnected {
    margin-left: 20px;
    margin-top: 5px;
}
</style>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';
import {startCase} from 'lodash';

export default {
    name: "ObsScenePanel",

    // Grid-Item Specific
    niceName: "OBS Scene Panel",
    description: "Controls scenes in OBS",
    options: {},
    width: 4,
    height: 1,
    hotkey(hotkeyData){
        // Check studio mode
        if (hotkeyData.scene != this.$store.state.obs.currentScene) {
            this.$store.commit("obs/CHANGE_SCENE", hotkeyData.scene);
        }
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    computed: {
        label(){
            return 'OBS ' + startCase(this.type);
        },
    },
    methods: {
        changeState(scene){
            // Check studio mode
            if (scene != this.$store.state.obs.currentScene) {
                this.$store.commit("obs/CHANGE_SCENE", scene);
            }
        },
        getState(scene) {
            if (this.$store.state.obs.currentScene == scene) return "live";
            if (this.$store.state.obs.transitioning === scene) return "live";
            return "off";
        },
        registerHotkey(scene){
            this.$store.commit('completeKeyRegistration', {
                name: "OBS Scene " + scene,
                componentName: this.$options.name,
                function: this.$options.hotkey,
                scene,
            });
        },
    },
};
</script>
