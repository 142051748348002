<template>
    <button-group label="ATEM Preview" :columns="$store.getters['atem/buttons'](switcher, me).length">
        <div v-if="this.$store.getters['atem/connected'](switcher) === 'disabled'" class="disconnected">
            Disabled
        </div>
        <div v-else-if="this.$store.getters['atem/connected'](switcher) === 'connected'">
            <real-button
                :key="i.id"
                v-for="i in $store.getters['atem/buttons'](switcher, me)"
                type="preview"
                :state="i.preview"
                @click="changeState(i.id)"
                @reghotkey="registerHotkey(i)"
            >{{ i.name }}</real-button>
        </div>
        <div v-else class="disconnected">
            Disconnected
        </div>
    </button-group>
</template>

<style lang="scss" scoped>
.disconnected {
    margin-left: 20px;
    margin-top: 5px;
}
</style>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';

export default {
    name: "AtemPreviewPanel",

    // Grid-Item Specific
    niceName: "ATEM Preview Panel",
    description: "Controls preview input for ATEM Switcher",
    width: 3,
    height: 1,
    options: {
        switcher: {
            name: "Switcher",
            default: 0,
            type: "select",
            options() {
                return this.$store.getters['atem/switchers'];
            },
        },
        me: {
            name: "ME",
            default: 0,
            type: "toggle",
            options(){
                if (this.selectedComponentOptions["switcher"] === "") return "Please select a switcher";
                if (!this.$store.getters['atem/connected'](this.selectedComponentOptions["switcher"])) return "Needs to be connected";
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me < 2) return { 0: "NO M/E" };
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me == 2) return { 0: "M/E 1", 1: "M/E 2" };
            },
        },
    },
    hotkey(hotkeyData){
        this.$store.commit('atem/SEND_COMMAND', {
            command: "SetPreviewInput",
            switcher: hotkeyData.switcher,
            data: {
                me: hotkeyData.me,
                input: hotkeyData.input,
            },
        });
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    props: {
        switcher: {
            type: Number,
            required: true,
        },
        me: {
            type: [String, Number],
            required: true,
        },
    },
    methods: {
        changeState(inputNumber){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: "SetPreviewInput",
                data: {
                    me: this.me,
                    input: inputNumber,
                },
            });
        },
        registerHotkey(input){
            this.$store.commit('completeKeyRegistration', {
                name: "ATEM Preview Input " + input.id,
                componentName: this.$options.name,
                function: this.$options.hotkey,
                me: this.me,
                input: input.id,
                switcher: this.switcher,
            });
        },
    },
};
</script>
