<template>
    <div class="photo-viewer" :class="size">
        <img v-if="preview" :src="preview">
        <img :src="program" :style="programStyle">
        <div v-if="$slots.default" class="overlay-title px-2 py-1 mx-auto"><slot></slot></div>
    </div>
</template>

<style lang="scss" scoped>
.photo-viewer {
    position: relative;
    display: inline-block;
    border: solid white 2px;
    margin: 0px;
    padding: 0px;
}

.photo-viewer.half {
    width: 12.5vw;
    height: 12.5vh;
}

.photo-viewer.single {
    width: 25vw;
    height: 25vh;
}

.photo-viewer.double {
    width: 50vw;
    height: 50vh;
}

.photo-viewer.full {
    width: 100vw;
    height: 100vh;
    border: none;
}

.photo-viewer img {
    height: 100%;
    width: 100%;
    position:absolute;
    left:0;
}

.photo-viewer .overlay-title {
    line-height: normal;
    position: absolute;
    display: flex;
    bottom: 5px;
    border-radius: 3px;
    background: #333333;
    border: white solid 2px;
    max-width: 80%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: white;
}

</style>

<script>
export default {
    props: {
        "preview": {
            type: String,
            default: "",
        },
        "program": {
            type: String,
            required: true,
        },
        "state": {
            type: Number,
            default: 0,
        },
        "type": {
            type: String,
            default: "",
        },
        "slots": {
            type: [Number, String],
            default: 1,
        },
    },
    computed: {
        size(){
            if (this.slots == 4 || this.slots == "full") {
                return "full";
            }
            if (this.slots == 2 || this.slots == "double") {
                return "double";
            }
            if (this.slots == 0.5 || this.slots == "half") {
                return "double";
            }
            return "single";
        },
        multiplier(){
            if (this.slots == 4 || this.slots == "full") {
                return 4;
            }
            if (this.slots == 2 || this.slots == "double") {
                return 3;
            }
            if (this.slots == 0.5 || this.slots == "half") {
                return 0.5;
            }
            return 1;
        },
    },
    data(){
        return {
            programStyle: {},
        };
    },
    watch: {
        state(val, oldVal){
            const programStyle = {};
            if (this.type == "mix") {
                programStyle['opacity'] = 1.0 - (val / 255.0);
            } else if (this.type == "wipe") {
                const dist = Math.round((25 * this.multiplier * val) / 255);
                programStyle['clip'] = 'rect(0px, 50vw, 50vh, ' + dist + 'vw)'; // top, right, bottom, left
            }
            this.programStyle = programStyle;
        },
    },
};
</script>
