<template>
    <button-group label="ATEM DSK" :columns="2" :rows="3">
        <div v-if="this.$store.getters['atem/connected'](switcher) === 'disabled'" class="disconnected">
            Disabled
        </div>
        <div v-else-if="this.$store.getters['atem/connected'](switcher) === 'connected'">
            <real-button type="select" :state="isActive('tied',0)" @click="toggleTie(0)" @reghotkey="registerHotkey('toogleTie',0)" :disabled="!$store.getters['atem/connected'](switcher)">
                Tie
            </real-button>
            <real-button type="select" :state="isActive('tied',1)" @click="toggleTie(1)" @reghotkey="registerHotkey('toogleTie',0)" :disabled="!$store.getters['atem/connected'](switcher)">
                Tie
            </real-button>
            <br>
            <real-button type="program" :state="isActive('live',0)" @click="toggleLive(0)" @reghotkey="registerHotkey('toggleLive',0)" :disabled="!$store.getters['atem/connected'](switcher)">
                Live
            </real-button>
            <real-button type="program" :state="isActive('live',1)" @click="toggleLive(1)" @reghotkey="registerHotkey('toggleLive',1)" :disabled="!$store.getters['atem/connected'](switcher)">
                Live
            </real-button>
            <br>
            <real-button type="program" :state="isActive('autoTransitioning',0)" @click="runAuto(0)" @reghotkey="registerHotkey('runAuto',0)" :disabled="!$store.getters['atem/connected'](switcher)">
                Auto
            </real-button>
            <real-button type="program" :state="isActive('autoTransitioning',1)" @click="runAuto(1)" @reghotkey="registerHotkey('runAuto',1)" :disabled="!$store.getters['atem/connected'](switcher)">
                Auto
            </real-button>
        </div>
        <div v-else class="disconnected">
            Disconnected
        </div>
    </button-group>
</template>

<style lang="scss" scoped>
.disconnected {
    margin-left: 20px;
    margin-top: 5px;
}
</style>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';

export default {
    name: "AtemDownstreamKeyPanel",
    niceName: "ATEM Downstream Keyer Panel",
    description: "Control downstream both downstream keys",
    height: 2,
    width: 2,
    options: {
        switcher: {
            name: "Switcher",
            default: 0,
            type: "select",
            options() {
                return this.$store.getters['atem/switchers'];
            },
        },
        // DSK's don't have an ME
    },
    hotkey(hotkeyData){
        if (hotkeyData.action == 'runAuto'){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: hotkeyData.switcher,
                command: "RunDSKAuto",
                data: {
                    keyer: hotkeyData.keyer,
                },
            });
        } else if (hotkeyData.action == 'toggleTie') {
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: hotkeyData.switcher,
                command: "SetDSKTie",
                data: {
                    keyer: hotkeyData.keyer,
                    tied: !this.$store.state.atem.switcherStates[hotkeyData.switcher].downstreamKeyers[keyer].tied,
                },
            });
        } else if (hotkeyData.action == 'toggleLive') {
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: hotkeyData.switcher,
                command: "SetDSKLive",
                data: {
                    keyer: hotkeyData.keyer,
                    live: !this.$store.state.atem.switcherStates[hotkeyData.switcher].downstreamKeyers[keyer].live,
                },
            });
        }
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    
    props: {
        switcher: {
            type: Number,
            required: true,
        },
        i: {
            type: Number,
            required: true,
        },
    },

    methods: {
        isActive(name, keyer){
            if (!this.$store.getters['atem/connected'](this.switcher)) return false;
            return this.$store.state.atem.switcherStates[this.switcher].downstreamKeyers[keyer][name];
        },
        runAuto(keyer){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: "RunDSKAuto",
                data: {
                    keyer,
                },
            });
        },
        toggleTie(keyer){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: "SetDSKTie",
                data: {
                    keyer,
                    tied: !this.$store.state.atem.switcherStates[this.switcher].downstreamKeyers[keyer].tied,
                },
            });
        },
        toggleLive(keyer){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: "SetDSKLive",
                data: {
                    keyer,
                    live: !this.$store.state.atem.switcherStates[this.switcher].downstreamKeyers[keyer].live,
                },
            });
        },
        registerHotkey(action, keyer){
            let name = "ATEM ";
            if (action == 'runAuto') name += 'Auto';
            else if (action == 'toggleTie') name += 'Tie';
            else if (action == 'toggleLive') name += 'Live';
            name += " DSK" + (keyer + 1);
            this.$store.commit('completeKeyRegistration', {
                name,
                componentName: this.$options.name,
                function: this.$options.hotkey,
                action,
                keyer,
                switcher: this.switcher,
            });
        },
    },
};
</script>
