import Vue from 'vue';

// From: https://stackoverflow.com/questions/6393943/convert-javascript-string-in-dot-notation-into-an-object-reference
function index(obj, is, value) {
    if (typeof is == 'string')
        return index(obj, is.split('.'), value);
    else if (is.length==1 && value!==undefined)
        return Vue.set(obj, is[0], value);
    else if (is.length==0)
        return obj;
    else
        return index(obj[is[0]], is.slice(1), value);
}

//const atemExample = require("./atemExample.json");
const atemExample = {};

export default {
    namespaced: true,
    mutations: {
        new(state){ // new switcher config
            const switcher = {
                ip: "192.168.1.2",
                name: "No Name",
                port: 4455,
                secure: false,
                enabled: true,
                autoconnect: true,
                inputNames: {},
                me: [
                    {
                        inputs: [],
                    },
                ],
            };
            state.switcherConfigs.push(switcher);
            state.switcherConnections.push({
                connected: false,
                open: false,
                socket: null,
            });
        },
        remove(state, switcher){
            state.switcherConfigs.splice(switcher, 1);
        },
        UPLOAD_CONFIG(state, config){
            for (let i = 0; i < state.switcherConfigs.length; i++){
                if (state.switcherConnections[i].socket !== null) {
                    state.switcherConnections[i].socket.close();
                }
            }
            Vue.set(state, 'switcherConfigs', config);
            state.switcherConnections = [];
            for (let i = 0; i < state.switcherConfigs.length; i++){
                Vue.set(state.switcherConnections, i, {
                    connected: false,
                    open: false,
                    socket: null,
                });
            }
        },
        init(state){
            state.switcherConnections = [];
            for (let i = 0; i < state.switcherConfigs.length; i++){
                state.switcherConnections.push({
                    connected: false,
                    open: false,
                    socket: null,
                });
            }
        },
        changeState(state, payload){
            for (const [key, value] of Object.entries(payload.state)) {
                if (key.indexOf('.') < 0) {
                    // use efficient method
                    Vue.set(state.switcherStates[payload.switcher], key, value);
                } else {
                    index(state.switcherStates[payload.switcher], key, value);
                }
            }
        },
        replaceState(state, payload){
            Vue.set(state.switcherStates, payload.switcher, payload.state);
            Vue.set(state.switcherConfigs[payload.switcher], "inputNames", payload.state.inputs);
            Vue.set(state.switcherConnections[payload.switcher], "connected", true);
        },
        connected(state, payload){
            if (payload.connected !== undefined) Vue.set(state.switcherConnections[payload.switcher], "connected", payload.connected);
            if (payload.open !== undefined) Vue.set(state.switcherConnections[payload.switcher], "open", payload.open);
            //console.log("ATEM Connected. State is", state.switcherStates[payload.switcher]);
            // Also put the input names into the switcherConfig for persistence
        },
        addSelectedInput(state, payload){
            // Payload: input
            state.switcherConfigs[payload.switcher].me[payload.me].inputs.push(payload.input);
        },
        moveSelectedInput(state, payload){
            // TODO: Reorder selected inputs
            // Payload: Input, Index (new)
            const inputs = state.switcherConfigs[payload.switcher].me[payload.me].inputs;
            const length = inputs.length;
            if (payload.index > length) {
                throw "Unable to move payload past end of array";
            }
            inputs.splice(payload.index, 0, inputs.splice(payload.oldIndex, 1)[0]);
            state.switcherConfigs[payload.switcher].me[payload.me].inputs = inputs;
        },
        removeSelectedInput(state, payload){
            // Payload: input OR index
            const length = state.switcherConfigs[payload.switcher].me[payload.me].inputs.length;
            let index = -1;
            if ('index' in payload){
                index = payload.index;
            } else if ('input' in payload){
                index = state.switcherConfigs[payload.switcher].me[payload.me].inputs.indexOf(payload.input);
            } else {
                throw "removeSelectedInput requires input or index to remove";
            }
            if (index > -1) {
                state.switcherConfigs[payload.switcher].me[payload.me].inputs.splice(index, 1);
            }
        },

        SEND_COMMAND(state, payload){
            // placeholder
        },
        CONNECT(state, payload){
            // Placeholder
        },
        DISCONNECT(state, payload){
            // Placeholder
        },
    },
    actions: {
        uploadConfig({commit, state}, config){
            // First disconnect from anything that's connected
            for (let i = 0; i < state.switcherConnections.length; i++){
                if (state.switcherConnections[i].connected){
                    commit('DISCONNECT', i);
                }
            }
            commit('UPLOAD_CONFIG', config);
            commit('init');
        },
    },
    getters: {
        buttons: state => (switcher, me) => {
            if (!state.switcherConnections[switcher].connected) return []; // Connected
            if (Object.keys(state.switcherStates[switcher].inputs).length < 1) return []; // Has the input configuration
            const inputSelect = state.switcherConfigs[switcher].me[0].inputs;
            const buttons = [];
            let inputNumber = 0;
            for (inputNumber of inputSelect){
                const button = {
                    name: state.switcherStates[switcher].inputs[inputNumber].shortName,
                    program: 0,
                    preview: 0,
                    id: inputNumber,
                };

                if (state.switcherStates[switcher].me[me].programIn == inputNumber) {
                    button.program = 2;
                }

                if (state.switcherStates[switcher].me[me].previewIn == inputNumber) {
                    button.preview = 1;
                    if (state.switcherStates[switcher].me[me].transitioning) button.preview += 4;
                }

                buttons.push(button);
            }

            return buttons;
        },
        switchers: state => {
            const switchers = {};
            for (let i = 0; i < state.switcherConfigs.length; i++) {
                switchers[i] = state.switcherConfigs[i].name;
            }
            return switchers;
        },
        connected: state => (switcher) => {
            if (state.switcherConfigs[switcher].enabled === false) return 'disabled';
            if (state.switcherConnections[switcher].open && state.switcherConnections[switcher].connected && state.switcherStates[switcher].connected) return 'connected';
            return false;
        },
        inputName: state => (switcher, input, long = true) => {
            if (isNaN(input)) {
                console.error("GetInputName recieved non-numerical", typeof input, input);
                return;
            }
            if (!('inputNames' in state.switcherConfigs[switcher])) return input;
            const inputObj = state.switcherConfigs[switcher].inputNames[input];
            if (inputObj === undefined) return input;
            if (long && 'longName' in inputObj) return inputObj.longName;
            if ('shortName' in inputObj) return inputObj.shortName;
            return input;
        },
    },
    state: {
        switcherConfigs: [],
        switcherStates: [],
        switcherConnections: [],
    },
};
