<template>
    <div>
        <div class="control-panel">
            <a class="m-2" title="Home" href="#/" target="_blank"><home-icon /></a>
            <a class="m-2" v-if="$store.state.simulator.enabled" title="Program" href="#/program" target="_blank"><cast-icon /></a>
            <a class="m-2" title="Control Panel" @click="showModal = true"><font-awesome-icon icon="cog" size="lg" /></a>
            <a class="m-2" title="Fullscreen" @click="goFullscreen"><maximize-icon v-if="!fullscreen" /><minimize-icon v-if="fullscreen" /></a>
        </div>
        <div class="modal fade" :class="{'show':showModal}" :style="{'display':(showModal)?'block':'none'}" id="cpmodal">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title"><font-awesome-icon icon="cogs" /> Control Panel</h2>
                        <button type="button" class="close" @click="showModal = false" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        No settings quite yet...
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="showModal = false">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.control-panel {
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    background: rgba(128,128,128,0.7);
    padding: 10px 3px;
    
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

.control-panel:hover {
    opacity: 1;
}

.control-panel a {
    color: white;
}

.control-panel a:hover {
    color: rgb(190, 190, 190);
    cursor: pointer;
}
</style>

<script>
import {
    HomeIcon, CastIcon, MaximizeIcon, MinimizeIcon,
} from 'vue-feather-icons';

export default {
    components: {
        HomeIcon,
        CastIcon,
        MaximizeIcon,
        MinimizeIcon,
    },
    mounted() {
        const self = this;
        document.addEventListener('fullscreenchange', function(e){
            self.fullscreen = (!!document.fullscreenElement || (window.fullScreen) || (window.innerWidth == screen.width && window.innerHeight == screen.height));
        });
    },
    data() {
        return {
            showModal: false,
            fullscreen: (!!document.fullscreenElement || (window.fullScreen) || (window.innerWidth == screen.width && window.innerHeight == screen.height)),
        };
    },
    methods: {
        goFullscreen(){
            const elem = document.documentElement;

            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            } else {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            }
        },
    },
};
</script>
