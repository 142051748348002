import { render, staticRenderFns } from "./AtemTransitionPanel.vue?vue&type=template&id=0863dede&scoped=true&"
import script from "./AtemTransitionPanel.vue?vue&type=script&lang=js&"
export * from "./AtemTransitionPanel.vue?vue&type=script&lang=js&"
import style0 from "./AtemTransitionPanel.vue?vue&type=style&index=0&id=0863dede&lang=scss&scoped=true&"
import style1 from "./AtemTransitionPanel.vue?vue&type=style&index=1&id=0863dede&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0863dede",
  null
  
)

export default component.exports