/** obsPlugin.js
 *  This connects to a obs websocket and handles events and sends commands.
 */

const OBSWebSocket = require('obs-websocket-js');

const obs = new OBSWebSocket();

export default function createOBSWebSocketPlugin() {
    return store => {
        obs.on('ConnectionOpened', () => {
            store.dispatch('obs/connectionOpened');
            // Update store
            obs.send("GetSceneList").then(function(data){
                store.dispatch("obs/setScenes", data);
            });
            obs.send("GetCurrentTransition").then(function(data){
                store.commit('obs/SET_TRANSITION', {'name': data.name, 'duration': data.duration });
            });
            obs.send("GetStreamingStatus").then(function(data){
                store.commit('obs/SET_STREAM_STATUS', ((data.streaming)? "started" : "stopped" ));
                store.commit('obs/SET_RECORD_STATUS', ((data.recording)? "started" : "stopped" ));
            });
        });
        obs.on("ConnectionClosed", () => {
            store.dispatch('obs/connectionClosed');
        });

        obs.on('SwitchScenes', data => {
            // What is in data??
            store.dispatch('obs/sceneSwitch', data);
        });

        obs.on('StreamStopped', () => {
            store.dispatch('obs/streamChange', "stopped");
        });

        obs.on('StreamStarted', () => {
            store.dispatch('obs/streamChange', 'started');
        });

        obs.on('StreamStarting', () => {
            store.dispatch('obs/streamChange', 'starting');
        });

        obs.on('StreamStopping', () => {
            store.dispatch('obs/streamChange', 'stopping');
        });

        obs.on('RecordingStopped', () => {
            store.dispatch('obs/recordingChange', "stopped");
        });

        obs.on('RecordingStarted', () => {
            store.dispatch('obs/recordingChange', 'started');
        });

        obs.on('RecordingStarting', () => {
            store.dispatch('obs/recordingChange', 'starting');
        });

        obs.on('RecordingStopping', () => {
            store.dispatch('obs/recordingChange', 'stopping');
        });

        obs.on('SwitchTransition', data => {
            store.commit('obs/SET_TRANSITION', {'name': data["transition-name"]});
        });

        obs.on('TransitionBegin', (data) => {
            store.commit('obs/SET_TRANSITIONING', data["to-scene"]);
        });

        obs.on('TransitionEnd', (data) => {
            store.commit('obs/SET_TRANSITIONING', false);
        });

        obs.on('TransitionDurationChanged', data => {
            store.commit('obs/SET_TRANSITION', {'duration': data["new-duration"]});
        });

        obs.on('error', err => {
            store.commit('obs/SET_ERROR', err);
        });

        // Subscribe to mutations :)
        store.subscribe((mutation, state) => {
            if (!state.obs.config.enabled) return;
            if (state.obs.connected){
                if (mutation.type === 'obs/TOGGLE_STREAM') {
                    if (state.obs.streaming === 1) {
                        obs.send('StartStreaming');
                    } else if (state.obs.streaming === 3) {
                        obs.send('StopStreaming');
                    }
                } else if (mutation.type === 'obs/TOGGLE_RECORDING') {
                    if (state.obs.recording === 1) {
                        obs.send('StartRecording');
                    } else if (state.obs.recording === 3) {
                        obs.send('StopRecording');
                    }
                } else if (mutation.type === 'obs/DISCONNECT') {
                    obs.disconnect();
                } else if (mutation.type === 'obs/CHANGE_SCENE'){
                    // Check if in scene list
                    if (state.obs.scenes.includes(mutation.payload)) {
                        obs.send('SetCurrentScene', {'scene-name': mutation.payload});
                    } else {
                        throw Exception("Scene out of bounds");
                    }
                } else if (mutation.type === 'obs/CHANGE_TRANSITION') {
                    if (typeof mutation.payload == "string") {
                        obs.send('SetCurrentTransition', {'transition-name': mutation.payload});
                    } else if (typeof mutation.payload == "number") {
                        obs.send('SetTransitionDuration', {duration: mutation.payload});
                    } else {
                        throw Exception("Invalid transition change type!");
                    }
                }
            } else {
                if (mutation.type === 'obs/CONNECT') {
                    obs.connect({ address: state.obs.config.hostname + ":" + state.obs.config.port, password: state.obs.config.password }).catch(function(e){
                        store.commit('obs/CONNECT_CANCEL');
                        store.commit('obs/SET_ERROR', e);
                    });
                }
            }
        });
    };
}
