<template>
    <div>
        <div class="control-panel">
            <a class="m-2" title="Home" href="#/" target="_blank"><home-icon /></a>
            <a class="m-2" v-if="$store.state.simulator.enabled" title="Multiview" href="#/multiview" target="_blank"><grid-icon /></a>
            <a class="m-2" title="Fullscreen" @click="goFullscreen"><maximize-icon v-if="!fullscreen" /><minimize-icon v-if="fullscreen" /></a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.control-panel {
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    background: rgba(128,128,128,0.7);
    padding: 10px 3px;
    opacity: 0;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out;
}

.control-panel:hover {
    opacity: 1;
}

.control-panel a {
    color: white;
}

.control-panel a:hover {
    color: rgb(190, 190, 190);
    cursor: pointer;
}
</style>

<script>
import {
    HomeIcon, GridIcon, MaximizeIcon, MinimizeIcon,
} from 'vue-feather-icons';

export default {
    components: {
        HomeIcon,
        GridIcon,
        MaximizeIcon,
        MinimizeIcon,
    },
    mounted() {
        const self = this;
        document.addEventListener('fullscreenchange', function(e){
            self.fullscreen = (!!document.fullscreenElement || (window.fullScreen) || (window.innerWidth == screen.width && window.innerHeight == screen.height));
        });
    },
    data() {
        return {
            showModal: false,
            fullscreen: (!!document.fullscreenElement || (window.fullScreen) || (window.innerWidth == screen.width && window.innerHeight == screen.height)),
        };
    },
    methods: {
        goFullscreen(){
            const elem = document.documentElement;

            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            } else {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            }
        },
    },
};
</script>
