<template>
    <div class="text">
        <vue-markdown :key="updates">
            {{ text }}
        </vue-markdown>
    </div>
</template>

<style scoped>
.text {
    color: white;
}
</style>

<script>
import VueMarkdown from 'vue-markdown';

export default {
    name: "GeneralText22",
    niceName: "General Text 2x2",
    width: 2,
    height: 2,
    description: "A text area you can write instructions or whatever you want in it.",
    
    options: {
        text: {
            name: 'Text',
            type: 'textarea',
            placeholder: "My Markdown",
        },
    },
    components: {
        VueMarkdown,
    },
    data() {
        return {
            updates: 0,
        };
    },
    props: {
        text: {
            type: String,
            default: "No Text",
        },
    },
    watch: {
        text(newval, oldval){
            this.updates += 1;
        },
    },

};
</script>
