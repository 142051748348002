<template>
    <div class="main" @mousedown="hold">
        <div class="background"></div>
        <input type="range" min="0" :max="max" v-model="slider" class="handle" :disabled="disabled" />
    </div>
</template>

<style lang="scss" scoped>
$background: #333333;

.main {
    position: relative;
    width: 200px;
    height: 400px;
}

.background {
    width: 160px;
    margin-left: 20px;
    height: 100%;
    background-color: $background;
    border-radius: 4px;
}

.handle {
    outline: none;
    position: absolute;
    width: 350px;
    border-radius: 5px;
    height: 10px;
    left: -75px;
    top: 195px;
    -webkit-appearance: none;
    appearance: none;
    background: black;
    transform: rotate(270deg);
    pointer-events: none;
}
/*.handle::-webkit-slider-runnable-track {
    width: 10px;
    height: 100%;
    cursor: pointer;
    //border: solid white 2px;
    //border-radius: 4px;
    //background-color: black;
}*/
.handle:focus {
    outline: none;
}
.handle::-webkit-slider-thumb  {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: url("/img/Handle.png");
    border: 1px solid #000000;
    opacity: 1;
    width: 40px;
    height: 180px;
    cursor: pointer;
    margin-top: -14px;
    border: none;
    pointer-events: auto;
}
</style>

<script>
export default {
    data(){
        return {
            inverted: false,
            slider: 0,
            running: false,
        };
    },
    props: {
        position: {
            type: Number,
            default: 0,
            required: false,
        },
        max: {
            type: Number,
            default: 255,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        hold(){
            this.$emit('mousedown');
        },
    },
    watch: {
        slider(val, OldVal){
            let realval = val;
            if (this.inverted) realval = this.max - val;

            if (realval == this.max) {
                this.inverted = !this.inverted;
                this.running = false;
            } else if (!this.running && realval > 0){
                this.running = true;
            } else if (this.running && realval == 0){
                this.running = false;
            }
            
            this.$emit("change", realval);
        },
        position(val, oldVal){
            if (this.inverted) this.slider = 255 - val;
            else this.slider = val;
        },
    },
};
</script>
