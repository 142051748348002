import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        broadcasters: {}, // by ID
    },
    mutations: {
        newBroadcaster(state){
            let nextKey = 0;
            for (id in Object.keys(state.broadcasters)){
                if (nextKey < id) nextKey = id + 1;
            }
            Vue.set(state.broadcasters, nextKey, {
                name: "Broadcaster",
                apiKey: "",
            });
        },
        setBroadcaster(state, payload){
            if ('name' in payload) Vue.set(state.broadcasters[payload.id], 'apiKey', payload.name);
            if ('key' in payload) Vue.set(state.broadcasters[payload.id], 'apiKey', payload.key);
        },
        rmBroadcaster(state, payload){
            Vue.delete(state.broadcasters, payload.id);
        },
    },
    getters: {
        broadcasters(state){
            const bc = {};
            for (const [id, info] of Object.entries(state.broadcasters)) {
                bc[id] = info.name;
            }
            return bc;
        },
    },
};
