<template>
    <div id="programview" v-if="$store.state.simulator.enabled">
        <fader-photo-viewer
            slots="full"
            :program="programFile"
            :preview="previewFile"
            :type="type"
            :state="state"
        />
        <program-c-p />
    </div>
    <div v-else>
        Program view is only available when using the simulator.
        <router-link to="/">Go Home.</router-link>
    </div>
</template>

<style lang="scss" scoped>
#programview {
    height: 100vh;
    width: 100vw;
    line-height: 0px;
}

</style>

<script>
/**
 * This page is a compliment to the switcher simulator multiview,
 * only giving the program output so it can be recorded.
*/
import FaderPhotoViewer from '@/components/Viewers/FaderPhotoViewer.vue';
import ProgramCP from '@/components/ControlPanels/ProgramCP';

export default {
    name: "program",
    components: {
        FaderPhotoViewer,
        ProgramCP,
    },
    computed: {
        program(){
            return this.$store.state.simulator.live;
        },
        preview(){
            return this.$store.state.simulator.preview;
        },
        state(){
            return this.$store.state.simulator.transitionState;
        },
        type(){
            return this.$store.state.simulator.transitionType;
        },
        previewFile(){
            return this.$store.getters['simulator/getInputData'](this.preview).file;
        },
        programFile(){
            return this.$store.getters['simulator/getInputData'](this.program).file;
        },
    },
};
</script>
