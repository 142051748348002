<template>
    <real-button
        @click="toggleStream"
        :state="state"
        :class="classes"
        :disabled="!$store.state.obs.connected"
        type="tally"
        @reghotkey="registerHotkey"
    >
        Stream
    </real-button>
</template>

<script>
const statusDef = [
    // LSB First; bit 1: OK, bit 2: started
    "stopping", // 00
    "stopped",  // 01
    "starting", // 10
    "started",  // 11
];

import RealButton from '@/components/basics/RealButton';

export default {
    name: 'ObsStreamButton',

    // Grid-Item Specific
    niceName: "OBS Stream Status Button",
    description: "Shows and Controls OBS Stream",
    options: {},
    width: 1,
    height: 1,
    hotkey(hotkeyData){
        this.$store.commit("obs/TOGGLE_STREAM");
    },

    // Vue
    components: {
        RealButton,
    },
    computed: {
        classes(){
            const blink = ((this.$store.state.obs.streaming == 0) || (this.$store.state.obs.streaming == 2));
            return {
                blink, // (0,2)
            };
        },
        state(){
            const streamStatus = this.$store.state.obs.streaming;
            if (streamStatus == 1) return "off";
            if (streamStatus == 0 || streamStatus == 3) return "live";
            if (streamStatus == 2) return "preview";
            return "off";
        },
    },
    methods: {
        toggleStream(){
            this.$store.commit("obs/TOGGLE_STREAM");
        },
        registerHotkey(){
            this.$store.commit('completeKeyRegistration', {
                name: "OBS Stream",
                componentName: this.$options.name,
                function: this.$options.hotkey,
            });
        },
    },
};
</script>
