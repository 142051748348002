const WebSocket = require('isomorphic-ws');

export default function createAtemWebSocketPlugin() {
    return store => {
        store.subscribe((mutation, state) => {
            const allowedCommands = [
                'atem/SEND_COMMAND',
                'atem/DISCONNECT',
                'atem/CONNECT',
            ];

            if (!allowedCommands.includes(mutation.type)) return;
            console.log("Recieved atem command", mutation.type);

            const switcher = mutation.payload.switcher;
            delete mutation.payload.switcher;

            if (state.atem.switcherConfigs[switcher]) {
                if (state.atem.switcherConnections[switcher].open && mutation.type === 'atem/DISCONNECT') {
                    if (state.atem.switcherConnections[switcher].socket && state.atem.switcherConnections[switcher].socket.readyState > 0) {
                        state.atem.switcherConnections[switcher].socket.close();
                    } else {
                        state.atem.switcherConnections[switcher].open = false;
                        state.atem.switcherConnections[switcher].connected = false;
                    }
                }
                if (mutation.type === 'atem/CONNECT'){
                    let connectstring = (state.atem.switcherConfigs[switcher].secure)? "wss://" : "ws://";
                    connectstring    += state.atem.switcherConfigs[switcher].ip;
                    connectstring    += ":" + state.atem.switcherConfigs[switcher].port;

                    state.atem.switcherConnections[switcher].socket = new WebSocket(connectstring);
                    state.atem.switcherConnections[switcher].socket.onopen = function open(){
                        store.commit('atem/connected', {switcher, open: true });
                    };
                    state.atem.switcherConnections[switcher].socket.onclose = function (){
                        store.commit('atem/connected', {switcher, connected: false, open: false });
                    };
                    state.atem.switcherConnections[switcher].socket.onmessage = function incoming(data){
                        const message = JSON.parse(data.data);
                    
                        if (typeof message.event !== 'string') return;
                    
                        if (message.event == 'connected') {
                            store.commit('atem/replaceState', {switcher, state: message.value});
                            store.commit('atem/connected', {switcher, connected: true });
                        } else if (message.event == 'stateChanged') {
                            store.commit('atem/changeState', {switcher, state: message.value });
                        } else if (message.event == 'disconnected') {
                            store.commit('atem/connected', {switcher, connected: false });
                        }
                    };
                }
                if (state.atem.switcherConnections[switcher].connected){
                    if (mutation.type === 'atem/SEND_COMMAND'){
                        //console.log("Sending command: ", mutation.payload);
                        state.atem.switcherConnections[switcher].socket.send(JSON.stringify(mutation.payload));
                    }
                }
            } else {
                console.error("ATEM config missing for switchr", switcher);
            }
        });
    };
}
