<template>
  <div id="app">
    <div class="app-border" v-if="$store.state.editing"></div>
    <div class="control-panel">
        <a class="m-2" v-if="!$store.state.editing" title="Edit" @click="startEdit"
            id="editButton"
        >
            <plus-icon />
        </a>
        <a class="m-2" v-if="$store.state.editing"  title="Stop Editing" @click="stopEdit">
            <check-icon />
        </a>
        <a class="m-2" title="Hotkeys" @click="hotkeysOpen = !hotkeysOpen"
            id="hotkeysButton"
        >
            <font-awesome-icon :icon="['fas','keyboard']" size="lg" />
        </a>
        <a class="m-2" v-if="$store.state.simulator.enabled" title="Multiview" href="#/multiview" target="_blank">
            <grid-icon />
        </a>
        <a class="m-2" v-if="$store.state.simulator.enabled" title="Program" href="#/program" target="_blank">
            <cast-icon />
        </a>
        <a class="m-2" title="Fullscreen" @click="goFullscreen" id="fullscreenButton"><maximize-icon v-if="!fullscreen" />
            <minimize-icon v-if="fullscreen" />
        </a>
        <a class="m-2" title="Update Available" @click="doUpdate" v-if="updateExists"><arrow-up-icon /></a>
    </div>
    <moveable-modal :visible="$store.state.editing" @close="stopEdit" header-color="#20CC20" id="editModal">
        <template v-slot:header>Edit Layout</template>
        <div class="form-group">
            <label>Type</label>
            <select class="form-control" v-model="filter" @change="selectedComponentName = ''">
                <option value="" disabled>Select One...</option>
                <option value="General">General</option>
                <option value="Simulator" v-if="$store.state.simulator.enabled">Simulator</option>
                <option value="Obs" v-if="$store.state.obs.config.enabled">OBS</option>
                <option value="Atem" v-if="$store.state.atem.switcherConfigs.length > 0">ATEM</option>
                <option value="SermonAudio">SermonAudio</option>
            </select>
        </div>
        <div class="form-group">
            <label>Add Component</label>
            <select
                class="form-control"
                v-model="selectedComponentName"
                @change="selectedComponentChange"
            >
                <option
                    value=""
                    disabled
                >
                    Select One...
                </option>
                <option
                    class="list-group-item"
                    v-for="component in filteredComponents"
                    :key="component.name"
                    :value="component.name"
                >
                    {{ component.niceName }}
                </option>
            </select>
        </div>
        <div v-if="selectedComponentName in gridComponents">
            <p v-if="gridComponents[selectedComponentName].description">
                {{gridComponents[selectedComponentName].description}}
            </p>
            <div class="form-group" v-for="(option,name) in Resolve(gridComponents[selectedComponentName].options)" :key="name">
                <label>{{option.name}}</label>
                <input
                    class="form-control"
                    :type="option.type"
                    :value="option.default"
                    v-if="['text','password'].includes(option.type)"
                    :placeholder="option.placeholder"
                    v-model="selectedComponentOptions[name]"
                >
                <div v-if="option.type == 'toggle'">
                    <div class="btn-group btn-group-toggle">
                        <div v-if="typeof Resolve(option.options) == 'string'">
                            {{Resolve(option.options)}}
                        </div>
                        <div v-else>
                            <button
                                class="btn btn-secondary"
                                v-for="(text,value) in Resolve(option.options)"
                                :key="value"
                                :value="value"
                                :class="{active:selectedComponentOptions[name] === value}"
                                @click="setComponentOption(name,value)"
                            >
                                {{text}}
                            </button>
                        </div>
                    </div>
                </div>
                <textarea
                    cols="30"
                    rows="10"
                    class="form-control"
                    v-if="option.type == 'textarea'"
                    v-model="selectedComponentOptions[name]"
                    :placeholder="option.placeholder"
                ></textarea>
                <select class="form-control" v-if="option.type == 'select'" v-model="selectedComponentOptions[name]">
                    <option value="">Select One...</option>
                    <option v-for="(text,value) in Resolve(option.options)" :key="value" :value="value">{{text}}</option>
                </select>
            </div>
        </div>
        <button class="btn btn-success" @click="addComponent(selectedComponentName,selectedComponentOptions)">Add</button>
    </moveable-modal>
    <moveable-modal :visible="hotkeysOpen" @close="hotkeysOpen = false" header-color="#BB1111">
        <template v-slot:header>Hotkeys</template>
        <button v-if="$store.state.newKeyListening === false" class="btn btn-primary" @click="$store.commit('initKeyRegistration')">
            Add Hotkey
        </button>
        <button v-if="$store.state.newKeyListening === true" class="btn btn-primary disabled">
            Press a key
        </button>
        <button v-if="typeof $store.state.newKeyListening == 'number'" class="btn btn-primary disabled">
            Click a button
        </button>
        <span v-if="typeof $store.state.newKeyListening == 'number'">
            Current Key: {{fromKeycode($store.state.newKeyListening)}}
        </span>
        <table v-if='Object.keys($store.state.hotkeys).length > 0'>
            <thead>
                <tr>
                    <th>Key</th>
                    <th>Function</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for='[key,config] of Object.entries($store.state.hotkeys)' :key="key">
                    <td>{{ fromKeycode(key) }}</td>
                    <td>{{ config.name }}</td>
                    <td><span @click="$store.commit('removeHotkey',key)"><trash-icon /></span></td>
                </tr>
            </tbody>
        </table>
        <div v-else>
            No Hotkeys Defined
        </div>
    </moveable-modal>
    <moveable-modal v-if="editItem !== false" @close="editItem = false" header-color="#BBBB11">
      <template v-slot:header>Editing</template>
        <div class="form-group" v-for="[optionName,option] of Object.entries(editItem.options)" :key="optionName">
            <label>{{option.name}}</label>
            <input
                class="form-control"
                :type="option.type"
                v-if="['text','password'].includes(option.type)"
                :placeholder="option.placeholder"
                v-model="$store.state.layout[editItem.index].props[optionName]"
            >
            <textarea
                cols="30"
                rows="10"
                class="form-control"
                v-if="option.type == 'textarea'"
                v-model="$store.state.layout[editItem.index].props[optionName]"
                :placeholder="option.placeholder"
            ></textarea>
            <div v-if="option.type == 'toggle'">
                <div class="btn-group btn-group-toggle">
                    <div>
                        <button
                            class="btn btn-secondary"
                            v-for="[subOptionName, subOption] of Object.entries(Resolve(option.options))"
                            :key="subOptionName"
                            :class="{active:$store.state.layout[editItem.index].props[optionName] === subOption.value}"
                            @click="updateLayoutProp('updateLayoutProp',editItem.index,subOptionName,subOption.value)"
                        >
                            {{subOption.text}}
                        </button>
                    </div>
                </div>
            </div>
            <select class="form-control" v-if="option.type == 'select'" v-model="$store.state.layout[editItem.index].props[optionName]">
                <option
                    v-for="[subOptionValue, subOptionText] of Object.entries(Resolve(option.options))"
                    :key="subOptionValue"
                    :value="subOptionValue"
                >
                    {{subOptionText}}
                </option>
            </select>
        </div>
    </moveable-modal>
    <div class="nocomponents" v-if="Object.entries($store.state.layout).length < 1">
        No components have been added. Click the plus below to get started.
    </div>
    <grid-layout
        :layout.sync="$store.state.layout"
        :col-num="12"
        :is-draggable="$store.state.editing"
        :is-resizeable="false"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[10,10]"
        :use-css-transforms="true"
        :auto-size="true"
    >
        <grid-item
            v-for="item in $store.state.layout"
            :key="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
        >
        <div v-if="$store.state.editing" class="grid-item-overlay" >
            <font-awesome-icon v-if="Object.keys(item.props).length > 0" icon="cog" @click="editComponent(item.i)"/>
            <div @click="removeComponent(item.i)">X</div>
        </div>
        <component v-bind:is="item.name" v-bind="item.props"  :i="item.i"></component>
        <div class="full-overlay modifying" v-if="editItem && (editItem.layoutIndex == item.i)"></div>
      </grid-item>

    </grid-layout>
  </div>
</template>

<style lang="scss">
$bodyBackground: #43464B;
$shadowColor: rgba(61, 61, 56, 0.911);
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
}
.app-border {
    border: solid red 2px;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

body {
    background-color: $bodyBackground;
}

.text-white {
    color: white;
}

.nocomponents {
    color: white;
    margin: 10px;
}

.grid-item-overlay {
    z-index: 5;
    position: absolute;
    right: 0px;
    top: 0px;
    color: red;
    background:rgba(61, 61, 56, 0.911);
    border-radius: 3px;
    cursor: pointer;
}

.grid-item-overlay .fa-cog {
    color: green;
}

.control-panel {
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 5px;
    background: rgba(128,128,128,0.7);
    padding: 10px 3px;
    z-index: 3;
}

.control-panel a {
    color: white;
}

.control-panel a:hover {
    color: rgb(190, 190, 190);
    cursor: pointer;
}

.modifying {
    -webkit-animation: hatching 10s linear reverse infinite;
    -moz-animation: hatching 10s linear reverse infinite;
    animation: hatching 10s linear reverse infinite;
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 10px,
        rgba(233, 255, 108, 0.3) 10px,
        rgba(255, 108, 108, 0.3) 20px
    );
    background-size: 200% 200%;
}
@-webkit-keyframes hatching {
    0%{background-position:0% 50%}
    100%{background-position:100% 50%}
}
@-moz-keyframes hatching {
    0%{background-position:0% 50%}
    100%{background-position:100% 50%}
}
@keyframes hatching {
    0%{background-position:0% 50%}
    100%{background-position:100% 50%}
}

.full-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    border-radius: 5px;
}

input-table {
    border: black solid 1px;
}

.dark-bg {
    background-color: rgba(32, 32, 32, 0.7);
}

</style>

<script>
import Vue from 'vue';
import VueGridLayout from 'vue-grid-layout';
import { fromKeycode } from '../keycodes';
import { kebabCase, camelCase, upperFirst } from 'lodash';
import MoveableModal from '../components/basics/MovableModal';
import DropdownButton from '../components/basics/DropdownButton';
import {
    PlusIcon,
    CheckIcon,
    GridIcon,
    CastIcon,
    MaximizeIcon,
    MinimizeIcon,
    TrashIcon,
    PlayIcon,
    ArrowUpIcon,
} from 'vue-feather-icons';

const components = {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DropdownButton,
    MoveableModal,
    PlusIcon,
    CheckIcon,
    GridIcon,
    CastIcon,
    MaximizeIcon,
    MinimizeIcon,
    TrashIcon,
    PlayIcon,
    ArrowUpIcon,
};

const requireComponent = require.context('../components/gridItems', false, /\.vue$/);

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));
    components[componentName] = componentConfig.default || componentConfig;
});

export default {
    name: 'controls',
    components,
    data(){
        // Find highest index in components
        let nextIndex = 0;
        let nextY = 0;
        let nextX = 0;
        for (const i in this.$store.state.layout){
        // This may be flawed - it is just a beginning.
            if (this.$store.state.layout[i].i >= nextIndex) {
                nextIndex = this.$store.state.layout[i].i + 1;
            }
            if (this.$store.state.layout[i].y >= nextY) {
                nextY = this.$store.state.layout[i].y + this.$store.state.layout[i].h - 1;
                nextX = this.$store.state.layout[i].x + this.$store.state.layout[i].w;
            }
        }

        if (nextX > 12){
            nextX = 0;
            nextY++;
        }

        return {
            streamStatus: 0,
            ignoreKeys: false,
            shift: false,
            ctrl: false,
            alt: false,
            gridComponents: {},
            nextIndex,
            nextY,
            nextX,
            showControlPanel: false,
            hotkeysOpen: false,
            switcher: "",
            filter: "",
            fullscreen: (
                !!document.fullscreenElement
                || (window.fullScreen)
                || (window.innerWidth == screen.width && window.innerHeight == screen.height)
            ),
            selectedComponentName: "",
            selectedComponentOptions: {},
            editItem: false,
            registration: null,
            updateExists: false,
            refreshing: false,
            keyListener: null,
            screenListener: null,
        };
    },
    created() {
        for ( const [name, component] of Object.entries(this.$options.components)) {
            if (["GridLayout", "GridItem", "ControlsControlPanel"].includes(name)) continue;
            if (!('niceName' in component)) continue;
            this.gridComponents[name] = {
                name: component.name,
                niceName: component.niceName || component.name,
                description: component.description,
                options: component.options,
                h: component.height || 1,
                w: component.width || 1,
                hotkey: component.hotkey || null,
            };
        }
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    computed: {
        filteredComponents(){
            const result = {}; // constant, but can assign new values
            const filter = this.filter.toLowerCase();
            if (this.filter == "") return result;
            for (const name in this.gridComponents){
                if (this.gridComponents[name].name.toLowerCase().startsWith(filter)){
                    result[name] = this.gridComponents[name];
                }
            }
            return result;
        },
    },
    mounted() {
        const self = this;

        for (const [keycode, hotkeyData] of Object.entries(this.$store.state.hotkeys)){
            //console.log("Processing hotkey", keycode, hotkeyData.componentName, typeof this.gridComponents[hotkeyData.componentName].hotkey, this.gridComponents);
            if (hotkeyData.componentName in this.gridComponents
                && 'hotkey' in this.gridComponents[hotkeyData.componentName]
                && typeof this.gridComponents[hotkeyData.componentName].hotkey == 'function'
            ){
                try {
                    hotkeyData.function = this.gridComponents[hotkeyData.componentName].hotkey;
                    this.$store.commit("reregisterHotkey", [keycode, hotkeyData]);
                } catch (e) {
                    console.error("Error registering hotkey for " + hotkeyData.componentName, e);
                }
            }
        }

        this.keyListener = window.addEventListener("keyup", function(e){
            const key = e.keyCode;
            // e.key = the actual character
            // e.location: general, left, right, numpad
            let adjKey = key;

            // Can also make note of e.location to determine location of certain keys,
            // like NUMPAD-Enter, or left/right shift
            // See https://keycode.info/

            if (e.altKey){
                adjKey += 0x100;
            }
            if (e.ctrlKey){
                adjKey += 0x200;
            }
            if (e.shiftKey){
                adjKey += 0x400;
            }

            if (self.$store.state.newKeyListening === true){
                if ((e.keyCode & 0xFF) == 27) self.$store.commit('cancelKeyRegistration');
                self.$store.commit('startKeyRegistration', adjKey);
                return;
            }
            if (typeof self.$store.state.newKeyListening == 'number' && e.keyCode == 27) {
                self.$store.commit('cancelKeyRegistration');
                return;
            }

            if (self.$store.state.ignoreKeys) return;
            if (self.$store.state.editing) return;

            if (adjKey in self.$store.state.hotkeys) {
                try {
                    self.$store.state.hotkeys[e.keyCode].function.call(self, self.$store.state.hotkeys[e.keyCode], self.$store.state.hotkeys[e.keyCode]);
                } catch (err) {
                    console.error("Error running hotkey for " + e.key + " from location " + e.location, err);
                }
            }
        });

        if (self.$store.state.obs.config.enabled && self.$store.state.obs.config.autoconnect && self.$store.state.obs.connected == false){
            // try to connect right away if OBS is set as current switcher on load
            self.$store.commit('obs/CONNECT');
        }

        for (let i = 0; i < self.$store.state.atem.switcherConfigs.length; i++){
            if (self.$store.state.atem.switcherConfigs[i].enabled && self.$store.state.atem.switcherConfigs[i].autoconnect) {
                self.$store.commit('atem/CONNECT', {switcher: i});
            }
        }

        this.screenListener = document.addEventListener('fullscreenchange', function(e){
            self.fullscreen = (
                !!document.fullscreenElement
                || (window.fullScreen)
                || (window.innerWidth == screen.width && window.innerHeight == screen.height)
            );
        });
    },
    destroy(){
        window.removeEventListener(this.keyListener);
        document.removeEventListener(this.screenListener);
    },
    methods: {
        changeStreamStatus(){
            this.streamStatus = (this.streamStatus == 0)? 2:0;
        },
        addComponent(component, options){
            // Component is a string of the component.
            // Example: {i:0,h:1,w:2,y:0,x:0,name:'SermonAudioWebcastStatus',props:{broadcaster:'smyrnabaptist'}}
            const obj = {
                i: this.nextIndex++,
                h: this.gridComponents[component].h,
                w: this.gridComponents[component].w,
                x: this.nextX,
                y: this.nextY,
                name: this.gridComponents[component].name,
                props: options,
            };
            // TODO: Go through props and get them from the form!
            this.$store.commit("addGridComponent", obj);
        },
        removeComponent(index){
            this.$store.commit("removeGridComponent", index);
        },
        editComponent(index){
            let component = null;
            for (let i = 0; i < this.$store.state.layout.length; i++){
                if (this.$store.state.layout[i].i == index){
                    component = this.$store.state.layout[i];
                    break;
                }
            }
            if (component === null) return;
            const self = this;
            const tempObj = {};
            this.$store.state.layout.forEach(function(obj, layoutIndex){
                if (obj.i == index) {
                    tempObj.options = self.Resolve(self.gridComponents[obj.name].options);
                    tempObj.index = layoutIndex;
                    tempObj.layoutIndex = index;
                    tempObj.props = obj.props;
                    self.editItem = tempObj;
                }
            });
        },
        goFullscreen(){
            const elem = document.documentElement;

            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            } else {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            }
        },
        startEdit(){
            this.$store.commit("setEdit", true);
        },
        stopEdit(){
            this.$store.commit("setEdit", false);
        },
        selectedComponentChange(){
            this.selectedComponentOptions = {};
            if ('options' in this.gridComponents[this.selectedComponentName]) {
                for (const [key, option] of Object.entries(this.gridComponents[this.selectedComponentName].options)) {
                    if ('default' in option) {
                        this.selectedComponentOptions[key] = option.default;
                    } else {
                        this.selectedComponentOptions[key] = "";
                    }
                }
            }
        },
        Resolve(options){
            if (typeof options == 'function') {
                return options.call(this);
            }
            return options;
        },
        setComponentOption(key, value){
            Vue.set(this.selectedComponentOptions, key, value);
        },
        fromKeycode,
        updateLayoutProp(index, name, value){
            this.editItem.props[name] = value;
            this.$store.commit('updateLayoutProp', index, name, value);
        },
        updateAvailable(event) {
            this.registration = event.detail;
            this.updateExists = true;
        },
        doUpdate(){
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) return;
            console.log("Skipping SW Waiting...");
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
    },
    watch: {
        showControlPanel(val, oldVal){
            if (val === true) this.$emit('showpanel');
            else this.$emit('hidepanel');
        },

    },
};
</script>
