<template>
    <button-group label="ATEM Transitions" :columns="3" :rows="2">
        <div v-if="this.$store.getters['atem/connected'](switcher) === 'disabled'" class="disconnected">
            Disabled
        </div>
        <div v-else-if="this.$store.getters['atem/connected'](switcher) === 'connected'">
            <real-button type="select" :state="activeStyle(0)" @click="setTrans(0)" @reghotkey="registerHotkey('SetTransition',0)" :disabled="!$store.getters['atem/connected'](switcher)">
                Mix
            </real-button>
            <real-button type="select" :state="activeStyle(1)" @click="setTrans(1)" @reghotkey="registerHotkey('SetTransition',1)" :disabled="!$store.getters['atem/connected'](switcher)">
                Dip
            </real-button>
            <real-button type="select" :state="activeStyle(2)" @click="setTrans(2)" @reghotkey="registerHotkey('SetTransition',2)" :disabled="!$store.getters['atem/connected'](switcher)">
                Wipe
            </real-button><br>
            <real-button type="program" :state="false" @click="runCut()" @reghotkey="registerHotkey('RunCut')" :disabled="!$store.getters['atem/connected'](switcher)">
                Cut
            </real-button>
            <real-button type="select" :state="false" @click="runAuto()" @reghotkey="registerHotkey('RunAuto')" :disabled="!$store.getters['atem/connected'](switcher)">
                Auto
            </real-button>
            <!--div class="auto-speed-div">
                <input v-mask="'####'" type="text" class="form-inline form-control auto-speed" size="4" maxlength="4" v-model="autoSpeed" @keyup.enter.stop="setAutoSpeed" @focus="$store.commit('ignoreKeys')" @blur="blurAutoSpeed" />
            </div-->
        </div>
        <div v-else class="disconnected">
            Disconnected
        </div>
    </button-group>
</template>

<style lang="scss" scoped>
.disconnected {
    margin-left: 20px;
    margin-top: 5px;
}
</style>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';

const transitions = [
    'Mix',
    'Dip',
    'Wipe',
    'Stinger',
    'DVE',
];

export default {
    name: "AtemTransitionPanel",

    // Grid-Item Specific
    niceName: "Atem Transition Panel",
    description: "Controls transitions on the ATEM Switcher",
    width: 2,
    height: 2,
    options: {
        switcher: {
            name: "Switcher",
            default: 0,
            type: "select",
            options() {
                return this.$store.getters['atem/switchers'];
            },
        },
        me: {
            name: "ME",
            default: 0,
            type: "toggle",
            options(){
                if (this.selectedComponentOptions["switcher"] === "") return "Please select a switcher";
                if (!this.$store.getters['atem/connected'](this.selectedComponentOptions["switcher"])) return "Needs to be connected";
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me < 2) return { 0: "NO M/E" };
                if (this.$store.state.atem.switcherStates[this.selectedComponentOptions["switcher"]].topology.me == 2) return { 0: "M/E 1", 1: "M/E 2" };
            },
        },
    },
    hotkey(hotkeyData){
        const data = {
            me: hotkeyData.me,
        };
        if (hotkeyData.command == 'setTransition') data.transition = hotkeyData.transition;
        this.$store.commit('atem/SEND_COMMAND', {
            command: hotkeyData.command,
            data,
            switcher: hotkeyData.switcher,
        });
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    //autoSpeed: this.$store.state.atem.switcherStates[this.switcher].me[this.me].mixTransitionDuration,
    computed: {
        autoSpeedComp(){
            if (this.$store.state.atem.switcherStates[this.switcher]) {
                return this.$store.state.atem.switcherStates[this.switcher].me[this.me].mixTransitionDuration;
            }
            return null;
        },
    },
    props: {
        switcher: {
            type: Number,
            required: true,
        },
        me: {
            type: [String, Number],
            required: true,
        },
    },
    
    watch: {
        autoSpeedComp(){
            if (this.$store.state.atem.switcherStates[this.switcher]) return;
            this.autoSpeed = this.$store.state.atem.switcherStates[this.switcher].me[this.me].mixTransitionDuration;
        },
    },
    methods: {
        activeStyle(input){
            if (!this.$store.getters['atem/connected'](this.switcher)) return false;
            return this.$store.state.atem.switcherStates[this.switcher].me[this.me].nextStyle == input;
        },
        setAutoSpeed(event){
            //this.$store.commit('obs/CHANGE_TRANSITION',Number(this.autoSpeed));
            event.target.blur();
        },
        blurAutoSpeed(){
            // TODO: complete changing transition duration for ATEM
            //this.autoSpeed = this.$store.state.atem.switcherState[0].mixTransitionDuration[0];
            this.$store.commit("listenKeys");
        },
        runCut(){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: 'RunCut',
                data: {
                    me: this.me,
                },
            });
        },
        runAuto(){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: 'RunAuto',
                data: {
                    me: this.me,
                },
            });
        },
        setTrans(transition){
            this.$store.commit('atem/SEND_COMMAND', {
                switcher: this.switcher,
                command: 'SetTransition',
                data: {
                    me: this.me,
                    transition,
                },
            });
        },
        registerHotkey(command, transition = 0){
            let name = "ATEM ";
            if (command == 'RunAuto') name += 'Auto';
            else if (command == 'RunCut') name += 'Cut';
            else name += 'Set Transition to ' + transitions[transition];
            const reg = {
                name,
                componentName: this.$options.name,
                function: this.$options.hotkey,
                me: this.me,
                command,
                switcher: this.switcher,
            };
            if (command == 'SetTransition') reg.transition = transition;
            this.$store.commit('completeKeyRegistration', reg);
        },
    },
};
</script>

<style scoped>

.auto-speed-div {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
}

.auto-speed {
  width: 60px;
  display: inline-block;
  color: yellow;
  background-color: black;
  border: none;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 0px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.auto-speed:focus {
  color: red;
  background-color: black;
  border: none;
}
</style>
