// OBS-Websocket

import Vue from 'vue';

const statusDef = [
    // bit 1: OK, bit 2: started
    "stopping", // 00
    "stopped",  // 01
    "starting", // 10
    "started",  // 11
];

export default {
    namespaced: true,
    state: {
        config: {
            enabled: true,
            autoconnect: true,
            hostname: 'localhost',
            port: 4444,
            password: 'secret',
        },
        connected: false,
        connecting: false,
        streaming: 1,
        recording: 1,
        error: null,
        currentScene: "",
        scenes: [],
        transitionName: "",
        transitionDuration: 300,
        transitions: [],
        transitioning: false,
    },
    getters: {
        streamState: state => statusDef[state.streaming],
    },
    mutations: {
        SET_CONNECTION(state, status){
            state.connected = status;
            state.connecting = false;
            if (status === false) {
                state.currentScene = "";
            }
        },
        SET_STREAM_STATUS(state, status){
            if (typeof status === "string") {
                status = statusDef.indexOf(status.toLowerCase());
            }
            state.streaming = status;
        },
        SET_RECORD_STATUS(state, status){
            if (typeof status === "string") {
                status = statusDef.indexOf(status.toLowerCase());
            }
            state.recording = status;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        CONNECT(state) {
            // Hook
            state.error = null;
            state.connecting = true;
        },
        CONNECT_CANCEL(state) {
            state.connecting = false;
        },
        DISCONNECT(state) {
            // Hook
        },
        TOGGLE_STREAM(state) {
            // Hook
        },
        TOGGLE_RECORDING(state) {
            // Hook
        },
        SET_SCENES(state, sceneList) {
            state.scenes = sceneList.map((obj) => obj.name);
        },
        SET_SCENE(state, scene) {
            state.currentScene = scene;
        },
        CHANGE_SCENE(state, scene){
            // Hook
        },
        SET_TRANSITION(state, data) {
            if (data.duration) state.transitionDuration = data.duration;
            if (data.name) state.transitionName = data.name;
        },
        CHANGE_TRANSITION(state, data) {
            // Hook
        },
        SET_TRANSITIONING(state, status) {
            state.transitioning = status;
        },
        ENABLE(state){
            Vue.set(state.config, 'enabled', true);
        },
        DISABLE(state){
            Vue.set(state.config, 'enabled', false);
        },
        UPLOAD_CONFIG(state, data){
            Vue.set(state.config, 'hostname', data.hostname);
            Vue.set(state.config, 'password', data.password);
            Vue.set(state.config, 'port', data.port);
            Vue.set(state.config, 'enabled', data.enabled);
            if (data.autoconnect !== undefined) Vue.set(state.config, 'autoconnect', data.autoconnect);
            state.connected = false;
            state.connecting = false;
        },
    },
    actions: {
        connectionOpened({ commit }){
            commit('SET_CONNECTION', true);
        },
        connectionClosed({ commit }){
            commit('SET_CONNECTION', false);
        },
        connectionError({ commit }, error){
            commit('SET_ERROR', error);
        },
        streamChange({ commit }, newState){
            if (typeof newState == "string") {
                newState = statusDef.indexOf(newState);
            }
            commit('SET_STREAM_STATUS', newState);
        },
        recordingChange({ commit }, newState){
            if (typeof newState == "string") {
                newState = statusDef.indexOf(newState);
            }
            commit('SET_RECORD_STATUS', newState);
        },
        setScenes({ commit }, data){
            commit('SET_SCENES', data.scenes);
            commit('SET_SCENE', data.currentScene);
        },
        sceneSwitch({ commit }, data){
            commit('SET_SCENE', data.sceneName);
        },
        disable({commit}){
            commit('DISCONNECT');
            commit('DISABLE');
        },
    },
};
