<template>
    <div class="blank" :class="{visible:$store.state.editing}">&nbsp;</div>
</template>

<style scoped>
.blank {
    width: 100%;
    height: 100%;
}
.blank.visible {
    border: 1px white solid;
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 10px,
        rgba(255,255,255,0.3) 10px,
        rgba(255,255,255,0.3) 20px
    );
}
</style>

<script>
export default {
    name: "GeneralBlank",
    niceName: "Blank",
    description: "Used for adding blank space to grid",
    height: 1,
    width: 1,
    options: {},
};
</script>
