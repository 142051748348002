<template>
        <div class="custom-file mb-3">
            <input type="file" class="custom-file-input" @change="upload" ref="uploadStateInput">
            <label class="custom-file-label">Load Configuration</label>
        </div>

</template>

<script>
function pad(number){
    return number.toString().padStart(2, '0');
}
export default {
    methods: {
        upload(evt){
            /* From Download:
            const data = {
                layout: this.$store.state.layout,
                obs: this.$store.state.obs,
                atem: this.$store.state.atem.switcherConfigs,
                hotkeys: this.$store.state.hotkeys,
                firstTime: this.$store.state.firstTime,
            };*/
            if (!confirm("Are you sure you want to replace the current state?")) {
                this.$refs.uploadStateInput.value = null;
                return;
            }
            const file = evt.target.files[0];
            const reader = new FileReader();
            reader.onload = e => {
                const data = JSON.parse(e.target.result);
                if ('version' in data && typeof data.version === 'number') {
                    if (data.version === 1) {
                        // Validate
                        if (!(
                            'layout' in data
                            && typeof data.layout == 'object'
                            && 'obs' in data
                            && typeof data.obs == 'object'
                            && 'atem' in data
                            && typeof data.atem == 'object'
                            && 'hotkeys' in data
                            && typeof data.hotkeys == 'object'
                            && 'firstTime' in data
                        )) {
                            alert("Uploaded file does not match desired format");
                            this.$refs.uploadStateInput.value = null;
                            return;
                        }
                        // layout
                        this.$store.commit("replaceLayout", data.layout);
                        // obs
                        this.$store.commit("obs/UPLOAD_CONFIG", data.obs);
                        // atem
                        this.$store.dispatch('atem/uploadConfig', data.atem);
                        // hotkeys
                        this.$store.commit('replaceHotkeys', data.hotkeys);
                        // firsttime
                        if (data.firsttime === true){
                            this.$store.commit('resetFirstTime');
                        } else {
                            this.$store.commit('completeFirstTime');
                        }
                        console.log(data);
                        alert("Done loading file");
                    } else {
                        alert("Version of file not supported");
                    }
                } else {
                    alert("No version number in file");
                }
                // Clear file input
                this.$refs.uploadStateInput.value = null;
            };
            reader.readAsText(file);
        },
    },
};
</script>
