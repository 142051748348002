<template>
    <div class="">
        <h1>Simulator</h1>
        <p>
            The simulator is a standalone video switcher simulator.
            It is capable of taking multiple inputs and switching between them, either using a cut, mix, or simple wipe transition.
        </p>
        <p>
            <b>At least two monitors are required to use the simulator properly</b>.
            One monitor is used for controls, while the other is used for the multiview page.
            There is also is a program page to view the program output full-screen.<br>
            You can access these pages by using the <grid-icon /> multiview and <cast-icon /> program icons at the bottom of the controls page.<br>
            Similar buttons are available on the multiview and program pages in the same corner, but they are hidden until you hover your mouse over them.
        </p>
        <p>
            More will appear here as the simulator gets more features. Proposed futures include
            <ul>
                <li>Multi-video player/streamer</li>
                <li>Drag-n-drop videos and pictures onto multiview</li>
                <li>Use camera inputs, enabling the simulator to act as a real switcher that can be used for live video.</li>
                <li>More transitions (wipes, dip, DVE)</li>
            </ul>
        </p>
    </div>
</template>

<script>
import {
    GridIcon,
    CastIcon,
} from 'vue-feather-icons';

export default {
    components: {
        GridIcon,
        CastIcon,
    },
};
</script>
