<template>
    <button class="btn btn-success" @click="download">
        Save Configuration
    </button>
</template>

<script>
function pad(number){
    return number.toString().padStart(2, '0');
}
export default {
    methods: {
        download(){
            const data = {
                version: 1,
                layout: this.$store.state.layout,
                obs: this.$store.state.obs,
                atem: this.$store.state.atem.switcherConfigs,
                hotkeys: this.$store.state.hotkeys,
                firstTime: this.$store.state.firstTime,
            };
            const output = JSON.stringify(data);
            const blob = new Blob([output], {type: 'text/plain'});
            const now = new Date();
            const name = `StreamerDashboardState-${now.getFullYear()}${pad(now.getMonth())}${pad(now.getDate())}${pad(now.getHours())}${pad(now.getMinutes())}.json`;

            // Now to create temporary element
            const e = document.createEvent('MouseEvents');
            const a = document.createElement('a');
            a.download = name;
            a.href = window.URL.createObjectURL(blob);
            a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
            e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            a.dispatchEvent(e);
        },
    },
};
</script>
