<template>
    <tee-slider
        @change="checkTransStatus"
        @mousedown="$store.commit('simulator/stopAuto')"
        :position="$store.state.simulator.transitionState"
    />
</template>

<script>
import TeeSlider from '@/components/basics/TeeSlider';

export default {
    name: "SimulatorTeeSlider",

    // Grid-Item Specific
    niceName: "Tee Slider",
    description: "Simulation tee-handle transition slider",
    options: {},
    width: 2,
    height: 3,

    // Vue
    components: {
        TeeSlider,
    },
    methods: {
        checkTransStatus(val){ // Callback from slider
            if (this.$store.state.simulator.transitionState != val) {
                this.$store.commit("simulator/setTransitionState", Number(val));
            }
        },
    },
};
</script>
