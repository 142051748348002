<template>
    <div id="multiview" v-if="$store.state.simulator.enabled">
        <photo-viewer
            :key="index"
            v-for="index in 8"
            :src="$store.getters['simulator/getInputData'](index).file"
            :live="program == index"
            :preview="preview == index"
        >
            <span v-if="$store.getters['simulator/getInputData'](index).name">
                <b>{{index}}</b>
                &nbsp;
                {{ $store.getters['simulator/getInputData'](index).name }}
            </span>
        </photo-viewer>
        <br>
        <fader-photo-viewer slots="2" :program="previewFile">
            Preview
        </fader-photo-viewer>
        <fader-photo-viewer slots="2" :program="programFile"
            :preview="previewFile" :type="type" :state="state">
            Program
        </fader-photo-viewer>
        <multiview-c-p />
    </div>
    <div v-else>
        Multiview is only available when using the simulator.
        <router-link to="/">Go Home.</router-link>
    </div>
</template>

<style lang="scss" scoped>
#multiview {
    height: 100vh;
    width: 100vw;
    line-height: 0px;
}

</style>

<script>
/**
 * The purpose of this page is to act as a simulator switcher.
 * For now, it is best not to use for actual streaming.
 * Switching is controlled by commands to the simulator model
 * in the store and is synced via localstorage.
*/

/* TODO List:
 - input config
 - WebRTC input
 - Drag n' Drop video, photo, or directory with sequential video
 - Project folder open/save
 - Record commands from control panel and save
 - Playback commands from file
 - Outside of browser: convert project with command file into actual output video.

*/

import FaderPhotoViewer from '@/components/Viewers/FaderPhotoViewer.vue';
import PhotoViewer from '@/components/Viewers/PhotoViewer.vue';
import MultiviewCP from '@/components/ControlPanels/MultiviewCP';

export default {
    name: "multiview",
    components: {
        FaderPhotoViewer,
        PhotoViewer,
        MultiviewCP,
    },
    computed: {
        program(){
            return this.$store.state.simulator.live;
        },
        preview(){
            return this.$store.state.simulator.preview;
        },
        state(){
            return this.$store.state.simulator.transitionState;
        },
        type(){
            return this.$store.state.simulator.transitionType;
        },
        previewFile(){
            return this.$store.getters['simulator/getInputData'](this.preview).file;
        },
        programFile(){
            return this.$store.getters['simulator/getInputData'](this.program).file;
        },
    },
};
</script>
