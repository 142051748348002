<template>
    <button-group label="OBS Transitions">
        <real-button type="select" :state="$store.state.obs.transitionName == 'Cut'" @click="$store.commit('obs/CHANGE_TRANSITION','Cut')" @reghotkey="registerHotkey('Cut')">
            Cut
        </real-button>
        <real-button type="select" :state="$store.state.obs.transitionName == 'Fade'" @click="$store.commit('obs/CHANGE_TRANSITION','Fade')" @reghotkey="registerHotkey('Fade')">
            Fade
        </real-button>
        <div class="auto-speed-div">
            <input v-mask="'####'" type="text" class="form-inline form-control auto-speed" size="4" maxlength="4" v-model="autoSpeed" @keyup.enter.stop="setAutoSpeed" @focus="$store.commit('ignoreKeys')" @blur="blurAutoSpeed" />
        </div>
    </button-group>
</template>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';

export default {
    name: "ObsTransitionPanel",

    // Grid-Item Specific
    niceName: "OBS Transition Panel",
    description: "Controls transitions in OBS",
    options: {},
    width: 2,
    height: 2,
    hotkey(hotkeyData){
        this.$store.commit('obs/CHANGE_TRANSITION', hotkeyData.transition);
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    data() {
        return {
            autoSpeed: this.$store.state.obs.transitionDuration,
        };
    },
    computed: {
        autoSpeedComp(){
            return this.$store.state.obs.transitionDuration;
        },
    },
    
    watch: {
        autoSpeedComp(){
            this.autoSpeed = this.$store.state.obs.transitionDuration;
        },
    },
    methods: {
        setAutoSpeed(event){
            this.$store.commit('obs/CHANGE_TRANSITION', Number(this.autoSpeed));
            event.target.blur();
        },
        blurAutoSpeed(){
            this.autoSpeed = this.$store.state.obs.transitionDuration;
            this.$store.commit("listenKeys");
        },
        registerHotkey(transition){
            this.$store.commit('completeKeyRegistration', {
                name: "OBS Transition " + scene,
                componentName: this.$options.name,
                function: this.$options.hotkey,
                transition,
            });
        },
    },
};
</script>

<style scoped>

.auto-speed-div {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
}

.auto-speed {
  width: 60px;
  display: inline-block;
  color: yellow;
  background-color: black;
  border: none;

  margin: 0;
  position: absolute;
  top: 50%;
  left: 0px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.auto-speed:focus {
  color: red;
  background-color: black;
  border: none;
}
</style>
