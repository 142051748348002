<template>
    <h2>{{ text }}</h2>
</template>

<style scoped>
h2 {
    color: white;
    filter: drop-shadow(3px 4px 3px #2c2c2c);
}
</style>

<script>
export default {
    name: "GeneralHeader",
    niceName: "Header",
    description: "Adds a header to the grid",
    height: 1,
    width: 4,
    options: {
        text: {
            name: 'Text',
            type: 'text',
            placeholder: "My Text",
            slot: true,
        },
    },

    props: {
        text: {
            type: String,
            default: "No Text",
        },
    },
};
</script>
