<template>
    <div class="tallyBtn" :class="color" @click="clicked">
        <span v-if="!disabled">
            <slot>Button</slot>
        </span>
        <div class="tallyBtnCircle"></div>
        <div class="diagnol rot-right pos-tr"></div>
        <div class="diagnol rot-left pos-tl"></div>
        <div class="diagnol rot-left pos-br"></div>
        <div class="diagnol rot-right pos-bl"></div>
    </div>
</template>

<style lang="scss" scoped>
$shaderColor: rgba(212, 212, 212, 0.726);
$shaderInsideColor: rgba(255, 255, 255, 0.1);
$shaderOutsideColor: rgba(212, 212, 212, 0.363);
$color-list: (
    "green"  #00CC00 #00DD00,
    "yellow" rgb(161, 161, 0) rgb(196, 196, 30),
    "red"    #FF3333 #FF5555,
);

@keyframes fade-glow {
    from { opacity: 0.8; }
    50% { opacity: 0.0; }
    to { opacity: 0.8; }
}

@-webkit-keyframes fade-glow {
    from { opacity: 0.8; }
    40% { opacity: 0.0; }
    60% { opacity: 0.0; }
    to { opacity: 0.8; }
}

@each $value in $color-list {
    $innerColorDark: darken( nth($value,2) , 10% );
    $outerColorDark: darken( nth($value,3) , 10% );

    .tallyBtn.is-#{nth($value,1)} {
        background-color: #{nth($value,2)};
        background-image: radial-gradient(#{nth($value,2)},#{nth($value,3)})
    }

    .tallyBtn.is-#{nth($value,1)}::before {
        background: #{nth($value,2)};
        border-radius: 10px;
        content: '';
        filter: blur(12px);
        opacity: .8;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
    }

    .tallyBtn.blink.is-#{nth($value,1)}::before {
        animation:fade-glow 1000ms infinite;
        -webkit-animation:fade-glow 1000ms infinite;
    }

    @keyframes fade-#{nth($value,1)} {
        from {
            background-image: radial-gradient(#{nth($value,2)},#{nth($value,3)})
        }
        12% {
            background-image: radial-gradient(darken( nth($value,2) , 5% ),darken( nth($value,3) , 5% ))
        }
        25% {
            background-image: radial-gradient(darken( nth($value,2) , 10% ),darken( nth($value,3) , 10% ))
        }
        33% {
            background-image: radial-gradient(darken( nth($value,2) , 10% ),darken( nth($value,3) , 15% ))
        }
        50% {
            background-image: radial-gradient(darken( nth($value,2) , 10% ),darken( nth($value,3) , 20% ))
        }
        63% {
            background-image: radial-gradient(darken( nth($value,2) , 10% ),darken( nth($value,3) , 15% ))
        }
        75% {
            background-image: radial-gradient(darken( nth($value,2) , 10% ),darken( nth($value,3) , 10% ))
        }
        87% {
            background-image: radial-gradient(darken( nth($value,2) , 5% ),darken( nth($value,3) , 5% ))
        }
        to {
            background-image: radial-gradient(#{nth($value,2)},#{nth($value,3)})
        }
    }

    .tallyBtn.blink.is-#{nth($value,1)} {
        animation: fade-#{nth($value,1)} 1000ms infinite;
        -webkit-animation: fade-#{nth($value,1)} 1000ms infinite;
    }
}

.tallyBtn.is-grey {
    background-color: #AAAAAA;
}

.tallyBtn.is-black {
    background-color: #000000;
}

.tallyBtn {
    position: relative;
    display: inline-block;
    width: 75px;
    height: 75px;

    padding-top: 20px;

    border-width: 1px;
    border-style: solid;
    border-color: $shaderColor;

    text-decoration: none;
    color: black;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 100;

    border-radius: 10px;

    text-align: center;

    margin: 10px;
    white-space: normal;
    word-wrap: break-word;

}

.tallyBtn span {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: bold;
    //text-align: center;

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.tallyBtnCircle {
    position: absolute;
    top: 0px;
    left: 0px;
    margin: 0px;
    //padding 0px;
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: $shaderColor;
    border-radius: 50%;
    background: radial-gradient($shaderInsideColor,$shaderOutsideColor);
}

.diagnol {
    position: absolute;
    width:0px;
    height:0px;
}

.diagnol:after{
  content:"";
  position:absolute;
  border-top:1px solid $shaderColor;
  width:13px;
}

.diagnol.rot-left:after{
  transform: rotate(45deg);
}

.diagnol.rot-right:after{
  transform: rotate(-45deg);
}

.pos-tr {
    top: 5px;
    right: 13px;
}

.pos-tl {
    top: 6px;
    left: 0px;
}

.pos-br {
    bottom: 6px;
    right: 14px;
}

.pos-bl {
    bottom: 6px;
    left: 1px;
}

</style>

<script>
export default {
    name: 'RealButton',
    props: {
        type: { // tally, preview, program, select
            type: String,
            required: true,
        },
        state: { // Depends on type
            type: [String, Number, Boolean],
            required: true,
        },
        disabled: {
            type: [Boolean],
            requred: false,
            default: false,
        },
        name: {
            type: [String],
            required: false,
            default: 'Unknown',
        },
    },
    computed: {
        color(){
            let newState = this.state;
            if (this.disabled) return "black";
            if (this.state == "program" || this.state == "live") newState = 2;
            if (this.state == "preview") newState = 1;
            if (this.state == "trans") newState = 4;
            switch (this.type) {
                case "program":
                case "live":
                    if (newState === true || (newState & 2) == 2) {
                        return "is-red";
                    }
                    return "is-grey";
                case "preview":
                    if ((newState & 4) == 4) {
                        return "is-red";
                    }
                    if (newState === true || (newState & 1) == 1) {
                        return "is-green";
                    }
                    return "is-grey";
                case "tally":
                    if (newState === true ||  newState === 1) {
                        return "is-green";
                    }
                    if (newState > 1) {
                        return "is-red";
                    }
                    return "is-grey";
                case "select":
                    if (newState === true || newState > 0) {
                        return "is-yellow";
                    }
                    return "is-grey";
                default:
                    return "is-black";
            }
        },
    },
    methods: {
        clicked(e){
            if (typeof this.$store.state.newKeyListening == 'number') {
                console.log("Emitting regHotKey");
                this.$emit('reghotkey');
            } else {
                if (this.$store.state.editing) return;
                this.$emit('click');
            }
        },
    },
};
</script>
