/** Switcher Simulator Vuex Store
 *
 */

const builtin = {
    "black": {
        name: "Black",
        file: '/img/black.png',
        short: 'BLK',
    },
};

export default {
    namespaced: true,
    state: {
        enabled: true,
        transitionRunning: false,
        transitionState: 0,
        transitionType: 'mix',
        live: 1,
        preview: 2,
        autoRunning: false,
        autoInterval: null,
        autoSpeed: 200, //ms
        inputs: [
            {
                name: "Auditorium",
                file: "/defaultsources/Auditorium.jpg",
                short: "AUD",
            },
            {
                name: "Classroom",
                file: "/defaultsources/classroom.jpg",
                short: "CLS",
            },
            {
                name: "Choir",
                file: "/defaultsources/girlsChoir.jpg",
                short: "CHR",
            },
        ],
    },
    mutations: {
        advanceTransition(state){
            if (state.transitionState > 254){
                state.transitionState = 0;
                state.transitionRunning = false;
                state.autoRunning = false;
                const oldLive = state.live;
                state.live = state.preview;
                state.preview = oldLive;
            }
            state.transitionState++;
        },
        setTransitionState(state, tstate){ // Manually set the transtion state
            if (tstate == 0) {
                state.transitionRunning = false;
            } else if (tstate > 254){
                state.transitionRunning = false;
                tstate = 0;
        
                // Switch Prog/Prev
                const oldLive = state.live;
                state.live = state.preview;
                state.preview = oldLive;
            } else {
                state.transitionRunning = true;
            }
            state.autoRunning = false;
            state.transitionState = tstate;
        },
        setLive(state, input){
            state.live = input;
        },
        setPreview(state, input){
            state.preview = input;
        },
        cutLive(state){
            const oldLive = state.live;
            state.live = state.preview;
            state.preview = oldLive;
        },
        setTransitionType(state, type){
            state.transitionType = type;
        },
    
        // Auto Actions
        startAuto(state){
            state.autoRunning = true;
        },
        stopAuto(state){
            state.autoRunning = false;
        },
        setAutoSpeed(state, val){
            state.autoSpeed = val;
        },

        enable(state){
            state.enabled = true;
        },
        disable(state){
            state.enabled = false;
        },
    },
    actions: {
        autoTransition({ commit, state, getters }) {
            if (state.autoRunning) {
                commit('stopAuto');
                return true;
            }
            commit('startAuto');
            return new Promise((resolve, reject) => {
                this.autoInterval = setInterval(function(){
                    if (!state.autoRunning){
                        clearInterval(this.autoInterval);
                        resolve();
                        return;
                    }
                    commit('advanceTransition');
                }, Math.round(getters.getAutoSpeedMilli / 254));
            });
        },
    },
    getters: {
        getAutoSpeedMilli(state){
            // 30 Frames in a second
            const seconds = Math.floor(state.autoSpeed / 100);
            const frames = (state.autoSpeed - (seconds * 100)) % 30;
            return Math.round((seconds * 1000) + ((frames * 1000) / 30));
        },
        getInputData: (state) => (input) => {
            if (state.inputs[input - 1] !== undefined && state.inputs[input - 1]['name'] !== undefined){
            // todo: check structure for input, type, etc. This will be loaded eventually via a file in a folder.
                return state.inputs[input - 1];
            } else {
                return {
                    file: "/img/black.png",
                    short: "",
                    disabled: true,
                };
            }
        },
    },
};
