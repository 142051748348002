<template>
    <div>
        <main-header v-if="!($route.name == 'multiview' || $route.name == 'program')"/>
        <transition name="fade">
            <router-view></router-view>
        </transition>
        <v-tour name="firsttime" :steps="steps" :callbacks="callbacks" />
    </div>
</template>

<style>
.orange-red {
    color: #FC6D27;
}
.gold {
    color: #FFD700;
}
.steelblue {
    color: #4682B4;
}
.purple {
    color: blueviolet;
}
</style>

<script>
import MainHeader from '@/components/MainHeader';

import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://20ead36163ff4182a03bd12eefccefa0@sentry.blackhawkelectronics.com/3",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

export default {
    name: 'app',
    components: {
        MainHeader,
    },
    data(){
        return {
            steps: [
                {
                    target: '.navbar-brand',
                    content: 'Before we get started, you can return to this page by clicking here as long as the welcome page is enabled. To disable this page, click on the "skip this page" button or disable it on the configuration page.',
                    before: type => new Promise((resolve, reject) => {
                        if (this.$route.name != 'welcome') this.$router.push({name: "welcome"});
                        resolve();
                    }),
                },
                // 1.1: Controls
                {
                    target: '#controlHeader',
                    content: 'The controls page is where all your controls are at',
                    before: type => new Promise((resolve, reject) => {
                        if (this.$route.name != 'controls') this.$router.push({name: "controls"});
                        resolve();
                    }),
                },
                {
                    target: '#editButton',
                    content: 'The dashboard uses a grid system to layout your controls. Click the plus to enter grid edit mode.',
                    before: type => new Promise((resolve, reject) => {
                        this.$store.commit("setEdit", false);
                        resolve();
                    }),
                },
                {
                    target: '#editModal',
                    content: "You can add controls from this window.<br>You can also move, edit, or remove a control while in edit mode.<br>To exit edit mode, close this window or click on the checkmark below.",
                    before: type => new Promise((resolve, reject) => {
                        this.$store.commit("setEdit", true);
                        resolve();
                    }),
                },
                {
                    target: '#hotkeysButton',
                    content: 'With this button, you can view and add hotkeys that control buttons from your keyboard',
                    before: type => new Promise((resolve, reject) => {
                        this.$store.commit("setEdit", false);
                        resolve();
                    }),
                },
                {
                    target: '#fullscreenButton',
                    content: 'Go fullscreen to minimize any distractions',
                    before: type => new Promise((resolve, reject) => {
                        if (this.$route.name != 'controls') this.$router.push({name: "controls"});
                        resolve();
                    }),
                },
                // Config
                {
                    target: '#configHeader',
                    content: 'Configure the application on the configuration page',
                    before: type => new Promise((resolve, reject) => {
                        if (this.$route.name != 'config') this.$router.push({name: "config"});
                        resolve();
                    }),
                },
                {
                    target: '#newAtemButton',
                    content: 'Add configuration for an ATEM switcher by clicking on this button.<br>Please note that this connects to an ATEM relay and not the ATEM itself.',
                    before: type => new Promise((resolve, reject) => {
                        if (this.$route.name != 'config') this.$router.push({name: "config"});
                        resolve();
                    }),
                    params: {
                        placement: 'left',
                    },
                },
                // Help
                {
                    target: '#helpHeader',
                    content: 'If you need any further information, visit the help page.',
                    before: type => new Promise((resolve, reject) => {
                        if (this.$route.name != 'help') this.$router.push({name: "help"});
                        resolve();
                    }),
                },
                
            ],
            callbacks: {
                onFinish: this.onFinish,
            },
        };
    },
    created(){
        this.$store.commit("atem/init");
    },
    methods: {
        onFinish(){
            this.$router.push({name: 'welcome'});
        },
    },
};
</script>
