<template>
    <real-button><slot></slot></real-button>
</template>

<script>
import RealButton from '@/components/basics/RealButton';

export default {
    name: 'SimulatorTallyButton',

    // Grid-Item Specific
    niceName: "Individual Item Control",
    description: "DOES NOT WORK YET!<br>Simulator input control button",
    options: {
        type: {
            name: "Type",
            type: "select",
            options: {
                program: "Program",
                preview: "Preview",
            },
        },
        input: {
            name: "Input",
            type: 'select',
            options: {
                1: "1",
                2: "2",
                3: "3",
                4: "4",
                5: "5",
                6: "6",
                7: "7",
                8: "8",
            },
        },
    },
    width: 1,
    height: 1,

    // Vue
    components: {
        RealButton,
    },
    computed: {
        status(){
            return "Stream";
        },
    },
    
};
</script>
