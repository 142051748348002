<template>
    <div class="panel" :style="{width: width, height: height}">
        <div class="label">{{ label }}</div>
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
$bodyBackground: #43464B;
$shadowColor: rgba(61, 61, 56, 0.911);

.panel .label {
  position: absolute;
  top: -20px;
  left: 10px;
  background-color: lighten($bodyBackground, 10%);
  border-radius: 5px;
  color: white;
  font-size: 20px;
  padding: 0px 5px;
  z-index: 4;
}

.panel {
  border-radius: 5px;
  border: rgb(248, 249, 250) solid 1px;
  background-color: darken($bodyBackground, 10%);
  color: white;
  padding-top: 10px;
  -webkit-box-shadow: 5px 5px 5px 6px $shadowColor;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    5px 5px 5px 6px $shadowColor;  /* Firefox 3.5 - 3.6 */
  box-shadow:         5px 5px 5px 6px $shadowColor;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  /* box-shadow: [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]; */
  white-space: nowrap;
}
</style>

<script>
export default {
    props: {
        label: {
            type: String,
            default: "No Label",
        },
        columns: {
            type: Number,
            default: 1,
        },
        rows: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        width(){
            if (this.columns < 3) return "195px";
            return (5 + (this.columns * 95)) + 'px';
        },
        height(){
            if (this.rows < 2) return "110px";
            return (10 + 100 * this.rows) + 'px';
        },
    },
};
</script>
