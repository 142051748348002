<template>
    <button-group :label="label" :columns="8">
        <real-button
            :key="i"
            v-for="i in 8"
            :type="type"
            :disabled="tallyDisabled(i)"
            :state="inputState(i)"
            @click="changeState(i,type)"
            @reghotkey="registerHotkey(i)"
        >{{tallyLabel(i)}}</real-button>
    </button-group>
</template>

<script>
import ButtonGroup from '@/components/basics/ButtonGroup';
import RealButton from '@/components/basics/RealButton';
import {startCase} from 'lodash';

export default {
    name: "SimulatorInputPanel",

    // Grid-Item Specific
    niceName: "Input Control Panel",
    description: "Switcher simulator input control panel",
    options: {
        type: {
            name: "Panel Type",
            default: "Program",
            type: "select",
            options: {
                program: "Program",
                preview: "Preview",
            },
        },
    },
    width: 5,
    height: 1,
    hotkey(hotkeyData) {
        if (this.$store.getters['simulator/getInputData'](hotkeyData.input).disabled) return;
        if (hotkeyData.type == "program") {
            this.$store.commit("simulator/setLive", hotkeyData.input);
        }
        if (hotkeyData.type == "preview"){
            this.$store.commit("simulator/setPreview", hotkeyData.input);
        }
    },

    // Vue
    components: {
        ButtonGroup,
        RealButton,
    },
    props: {
        type: {
            type: String,
            default: "Program",
        },
        instant: { // not used yet
            type: Boolean,
            default: false,
        },
        i: {
            type: Number,
            required: true,
        },
    },
    computed: {
        label(){
            return 'Simulator ' + startCase(this.type);
        },
    },
    methods: {
        tallyLabel(index){
            const data = this.$store.getters['simulator/getInputData'](index);
            if (data.short) return data.short;
            return index;
        },
        tallyDisabled(index){
            return this.$store.getters['simulator/getInputData'](index).disabled === true;
        },
        
        changeState(input, state){
            if (this.$store.getters['simulator/getInputData'](input).disabled) return;
            if (state == "program") {
                this.$store.commit("simulator/setLive", input);
            }
            if (state == "preview"){
                this.$store.commit("simulator/setPreview", input);
            }
        },
        inputState(input){
            let output = 0;
            if (this.$store.state.simulator.live == input) output += 2;
            if (this.$store.state.simulator.preview == input) output += 1;
            if (this.$store.state.simulator.preview == input && this.$store.state.simulator.transitionRunning) output += 4;
            return output;
        },
        registerHotkey(input){
            this.$store.commit('completeKeyRegistration', {
                name: 'Simulator ' + this.type + ' ' + input,
                componentName: this.$options.name,
                function: this.$options.hotkey,
                input,
                type: this.type,
            });
        },
    },
};
</script>
