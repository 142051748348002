<template>
    <div>
        <main class="container">
            <h1 class="text-center mb-5">Welcome to your Streaming Dashboard!</h1>
        
            <h2>What is the Streaming Dashboard?</h2>
            <p>
                The streaming dashboard is a web application that enables you
                to control all the aspects of your streaming setup on one page.
                Instead of having to switch between OBS and ATEM software control,
                or having multiple computers to control everyting, you can control
                everything from one page.
            </p>
            <h2>What can the Streaming Dashboard do?</h2>
            <p>
                <b>Flexibility:</b>
                The Streaming Dashboard is designed to adapt to your needs.
                Disable what you don't need.
            </p>
            <p>
                <b>Connectivity:</b> The dashboard connects to devices and software through relays.
                OBS and ATEM swtichers are currently the only supported devices.
                For more information, please see the <router-link :to="{path:'help/relays'}">relays page</router-link>.
            </p>
            <p>
                <b>Training:</b>
                The dashboard contains a video switcher simulator.
                Instead of using the only switcher you have, you can train
                multiple new volunteers switching concepts using a laptop and external monitor.
            </p>
            <h2>Let's get started!</h2>
            <p>
                Start by taking a rather short tutorial.
                <br>
                <button class="btn btn-success mt-3" @click="start">Start Tutorial</button>
            </p>
            <p>If you would like to hide this page and always go right away to your dashboard, you can <a class="badge badge-pill badge-info pointer" @click="hide">skip this page</a>. This page can be viewed again by enabling it on the config page.</p>
        </main>
    </div>
</template>

<style scoped>
.pointer {
    cursor: pointer !important;
}

main {
    color: white;
}

a:not(.badge) {
    color: steelblue;
}
</style>

<script>
export default {
    methods: {
        hide(){
            this.$store.commit('completeFirstTime');
            this.$router.push({ name: 'controls'});
        },
        start(){
            this.$tours['firsttime'].start();
        },
    },
};
</script>
